<div *ngIf="isLoading" class="{{ theme }} spinner">
  <mat-spinner color="purple" diameter="150" strokeWidth="10" class="{{ theme }}"></mat-spinner>
</div>

<div class="{{ theme }} {{ className }}" *ngIf="dataCategory && !isLoading">
  <div class="{{ theme }} {{ className }}__category">
    <div class="{{ theme }} {{ className }}__category-wrapper">
      <h3 class="{{ theme }} {{ className }}__category-title" id="title-{{utilsService.removeSpacesAndInsertScript(dataCategory.name)}}" >
        <ng-container *ngIf="dataCategory.icon.includes('ai.svg')">
          <img
            class="{{ theme }} {{ className }}__category-title-img"
            src="assets/images/icons/ai_blue.svg"
            alt="{{ dataCategory.name }}" />
        </ng-container>
        <ng-container *ngIf="dataCategory.icon.includes('b.svg')">
          <img
            class="{{ theme }} {{ className }}__category-title-img"
            src="assets/images/icons/b_blue.svg"
            alt="{{ dataCategory.name }}" />
        </ng-container>
        <ng-container
          *ngIf="!dataCategory.icon.includes('ai.svg') && !dataCategory.icon.includes('b.svg')">
          <mat-icon class="{{ theme }} {{ className }}__category-title-icon">{{
            dataCategory.icon
          }}</mat-icon>
        </ng-container>
        <span class="{{ theme }} {{ className }}__category-title-text">{{
          dataCategory.name
        }}</span>
      </h3>
    </div>
    <ng-container
      *ngIf="
        dataCategory.applications.length > 0 &&
        dataCategory.type !== 1 &&
        dataCategory.type !== 3 &&
        dataCategory.type !== 4
      ">
      <ul class="{{ theme }} {{ className }}__category-list">
        <ng-container *ngFor="let app of dataCategory.applications; trackBy: identify">
          <ng-container *ngIf="app.enabled && !app.isPublic">
            <li class="{{ theme }} {{ className }}__category-list-item">
              <app-card-horizontal
                [theme]="theme"
                [isDesktop]="isDesktop"
                [width]="width"
                [categoryFeaturedId]="categoryFeaturedId"
                [categorySpecialId]="categorySpecialId"
                [categoryPurchasedId]="categoryPurchasedId"
                [categoryMostPurchasedId]="categoryMostPurchasedId"
                [categoryFeatured]="categoryFeatured"
                [categorySpecial]="categorySpecial"
                [categoryPurchased]="categoryPurchased"
                [categoryMostPurchased]="categoryMostPurchased"
                (moreButtonEvent)="onClickMoreButton(app._id)"
                [_data]="app"></app-card-horizontal>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </ng-container>
    <ng-container
      *ngIf="
        (dataCategory.type === 1 || dataCategory.type === 3 || dataCategory.type === 4) &&
        dataCategory.applications.length > 0
      ">
      <ul class="{{ theme }} {{ className }}__category-list">
        <ng-container *ngFor="let app of dataCategory.applications; trackBy: identify">
          <ng-container *ngIf="app.enabled && !app.isPublic">
            <li class="{{ theme }} {{ className }}__category-list-item">
              <app-card-vertical
                [theme]="theme"
                [isDesktop]="isDesktop"
                [width]="width"
                [categoryFeaturedId]="categoryFeaturedId"
                [categorySpecialId]="categorySpecialId"
                [categoryPurchasedId]="categoryPurchasedId"
                [categoryMostPurchasedId]="categoryMostPurchasedId"
                [categoryFeatured]="categoryFeatured"
                [categorySpecial]="categorySpecial"
                [categoryPurchased]="categoryPurchased"
                [categoryMostPurchased]="categoryMostPurchased"
                [showText]="showText"
                (moreButtonEvent)="onClickMoreButton(app._id)"
                [_data]="app"></app-card-vertical>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </ng-container>
    <p
      class="{{ theme }} {{ className }}__category-list-no-data"
      *ngIf="dataCategory.applications.length === 0">
      {{ labels.noAppsFound }}
    </p>
  </div>
  <!--Featured-->
  <ng-container
    *ngIf="
      dataCategory._id !== categoryFeaturedId &&
      dataCategory._id !== categoryMostPurchasedId &&
      dataCategory._id !== categorySpecialId &&
      categoryFeatured.applications?.length > 0
    ">
    <div class="{{ theme }} {{ className }}__category-featured">
      <div class="{{ theme }} {{ className }}__category-wrapper">
        <h3 class="{{ theme }} {{ className }}__category-title">
          <mat-icon class="{{ theme }} {{ className }}__category-title-icon">{{
            categoryFeatured.icon
          }}</mat-icon>

          <span class="{{ theme }} {{ className }}__category-title-text">{{
            categoryFeatured.name
          }}</span>
        </h3>
      </div>
      <p
        class="{{ theme }} {{ className }}__category-list-no-data"
        *ngIf="categoryFeatured.applications.length === 0">
        {{ labels.noAppsFound }}
      </p>
      <ul
        class="{{ theme }} {{ className }}__category-list"
        *ngIf="categoryFeatured.applications.length > 0">
        <ng-container
          *ngFor="let app of arrayApplicationFeatured; trackBy: identify; let i = index">
          <ng-container *ngIf="app.enabled">
            <li
              *ngIf="width >= 3800 ? i <= 4 : width < 3800 && width >= 1800 ? i <= 3 : i <= 2"
              class="{{ theme }} {{ className }}__category-list-item">
              <app-card-vertical
                *ngIf="app.enabled"
                [theme]="theme"
                [isDesktop]="isDesktop"
                [width]="width"
                [categoryFeaturedId]="categoryFeaturedId"
                [categorySpecialId]="categorySpecialId"
                [categoryPurchasedId]="categoryPurchasedId"
                [categoryMostPurchasedId]="categoryMostPurchasedId"
                [categoryFeatured]="categoryFeatured"
                [categorySpecial]="categorySpecial"
                [categoryPurchased]="categoryPurchased"
                [categoryMostPurchased]="categoryMostPurchased"
                [showText]="true"
                (moreButtonEvent)="onClickMoreButton(app._id)"
                [_data]="app"></app-card-vertical>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</div>
