import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonLabels } from '../../../../common/models/common.model';
import { commonLabels } from '../../../../common/common.labels';
import { Router } from '@angular/router';
import { AuthService } from '../../../../common/services/auth.service';
import { KeycloakService } from 'keycloak-angular';
import { CategoriesStoreService } from '../../../../common/services/category-observable.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { environment } from '../../../../../environments/environment';

@AutoUnsubscribe()
@Component({
  selector: 'app-header-public',
  templateUrl: 'header-public.component.html',
  styleUrls: ['header-public.component.scss']
})
export class HeaderPublicComponent implements OnInit, OnDestroy {
  @Input() isDesktop: boolean;
  @Input() width: number;
  @Input() theme: string;
  @Input() showMenu? = true;
  @Input() showMenuEcosystem? = false;
  @Output() closeMenuEmitter: EventEmitter<any> = new EventEmitter();
  @Output() openMenuEmitter: EventEmitter<any> = new EventEmitter();
  @Output() closeMenuEcosystemEmitter: EventEmitter<any> = new EventEmitter();
  @Output() openMenuEcosystemEmitter: EventEmitter<any> = new EventEmitter();
  @Output() closeSessionEmitter: EventEmitter<any> = new EventEmitter();
  @Output() openWindowEmitter: EventEmitter<any> = new EventEmitter();
  className = 'header';
  labels: CommonLabels = commonLabels;
  protected readonly environment = environment;

  constructor(
    private router: Router,
    private authService: AuthService,
    private readonly keycloak: KeycloakService,
    private categoriesStoreService: CategoriesStoreService
  ) {}

  ngOnInit() {}

  openMenu(): void {
    this.categoriesStoreService.setStateMenu('open');
    this.openMenuEmitter.emit('openMenu');
  }

  closeMenu(): void {
    this.categoriesStoreService.setStateMenu('close');
    this.closeMenuEmitter.emit({ action: 'closeMenu' });
  }

  openMenuEcosystem(): void {
    this.openMenuEcosystemEmitter.emit('openMenuEcosystem');
  }

  closeMenuEcosystem(): void {
    this.closeMenuEcosystemEmitter.emit('closeMenuEcosystem');
  }

  openWindow(url, target) {
    this.openWindowEmitter.emit({ url: url, target: target });
  }

  public login() {
    this.keycloak.login();
  }

  ngOnDestroy() {}
}
