import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BannerModel, CategoryModel, CommonLabels } from 'app/common/models/common.model';
import { commonLabels } from 'app/common/common.labels';
import { StorageService } from '../../../../common/services/storage.service';
import { ResizeWindowService } from 'app/common/services/resize-window.service';
import { CategoriesService } from '../../../../common/services/categories.service';
import { CategoriesStoreService } from '../../../../common/services/category-observable.service';
import { UtilsService } from '../../../../common/services/utils.service';
import { SortCriteria } from 'app/common/utils/sort-by.pipe';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { SnackMessageService } from '../../../../common/services/snackMessage.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  theme = 'marketplace-default';
  className = 'app-home';
  labels: CommonLabels = commonLabels;
  isDesktop: boolean = false;
  width: number = 0;
  isLoading: boolean = true;

  // Mocks
  dataBanner: BannerModel = {
    title1: 'Already a member? Log in to access your account.',
    title2: 'New here? Contact us!!',
    text: 'Contact our sales team to start deploying your applications in the edge',
    buttonId: 'button-contact-us',
    button: 'Contact us',
    srcImg: 'assets/images/banners/banner.svg',
    srcImgMobile: 'assets/images/banners/banner.png'
  };
  public categoryFeaturedId; //Featured
  public categorySpecialId; //Barbara Basics
  public categoryMostPurchasedId; //Most used
  public categoryPurchasedId; //Already in library
  public dataCategory = [];
  public categoryFeatured: CategoryModel = undefined;
  public categorySpecial: CategoryModel = undefined;
  public categoryMostPurchased: CategoryModel = undefined;
  public categoryPurchased: CategoryModel = undefined;
  public criteria: SortCriteria;
  arrayApplicationFeatured: any = [];

  constructor(
    private readonly resizeWindowService: ResizeWindowService,
    private storageService: StorageService,
    private categoriesService: CategoriesService,
    private categoriesStoreService: CategoriesStoreService,
    private snackMessageService: SnackMessageService,
    private readonly router: Router,
    public utilsSevice: UtilsService
  ) {
    // For pipe sort
    this.criteria = {
      property: 'name',
      descending: false
    };
  }

  ngOnInit() {
    this.isLoading = true;
    //subscribe to the window resize event
    this.resizeWindowService.isDesktop$.subscribe((value: boolean) => {
      this.isDesktop = value;
    });
    this.resizeWindowService.width$.subscribe(
      (value: number) => {
        this.width = value;
        this.isLoading = false;
        this.resizeWindowService.waitForFinalEvent(
          () => {
            this.goTop();
            this.categoriesStoreService.getCategoryList().subscribe(
              (categories: any) => {
                this.dataCategory = categories;
                this.filterApplicationsPrivate(this.dataCategory);
                this.isLoading = false;
              },
              (error) => {
                this.isLoading = false;
                this.snackMessageService.sendMessage(error.error.response.errorMessage);
              }
            );
          },
          500,
          'loadingHome'
        );
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );

    this.categoriesStoreService.getPurchasedCategoryId().subscribe(
      (id: string) => {
        this.categoryPurchasedId = id;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );

    this.categoriesStoreService.getMostPurchasedCategoryId().subscribe(
      (id: string) => {
        this.categoryMostPurchasedId = id;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getSpecialCategoryId().subscribe(
      (id: string) => {
        this.categorySpecialId = id;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getFeaturedCategoryId().subscribe(
      (id: string) => {
        this.categoryFeaturedId = id;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getPurchasedCategory().subscribe(
      (category: CategoryModel) => {
        this.categoryPurchased = category;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getMostPurchasedCategory().subscribe(
      (category: CategoryModel) => {
        this.categoryMostPurchased = category;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getSpecialCategory().subscribe(
      (category: CategoryModel) => {
        this.categorySpecial = category;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getFeaturedCategory().subscribe(
      (category: CategoryModel) => {
        this.categoryFeatured = category;
        if (category.applications?.length > 0) {
          this.categoryFeatured.applications = category.applications.filter((application) => {
            return application.isPublic === false;
          });
          if (this.categoryFeatured.applications.length > 5) {
            this.randomApp();
          } else {
            this.arrayApplicationFeatured = this.categoryFeatured.applications;
          }
        }
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
  }

  filterApplicationsPrivate(categoryList: CategoryModel[]) {
    let _temp = categoryList.map((category) => {
      category.applications = category.applications.filter((application) => {
        return application.isPublic === false;
      });
    });
    return _temp;
  }

  ngAfterViewInit(): void {
    this.goTop();
  }

  onClickMoreButton(id: string): void {
    this.storageService.setUrl('application-detail/' + id);
    this.categoriesStoreService.setStateMenu('close');
    this.router.navigate(['application-detail', id]);
  }

  clickCategorySeeAll(id: string): void {
    this.storageService.setCategoryId('category-detail/' + id);
    this.categoriesStoreService.setStateMenu('close');
    this.router.navigate(['category-detail', id]);
  }

  goTop() {
    //Para que el scroll esté al inicio de la página
    document.getElementsByClassName('mat-drawer-content')[0].scrollTo(0, 0);
  }

  identify(index, item) {
    return index;
  }

  randomApp() {
    let numberRandom = [];
    this.arrayApplicationFeatured = [];
    this.utilsSevice.randomNumber(this.categoryFeatured.applications, numberRandom);
    numberRandom.forEach((idx) => {
      this.arrayApplicationFeatured.push(this.categoryFeatured.applications[idx]);
    });
  }

  ngOnDestroy() {}
}
