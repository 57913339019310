import { Injectable } from '@angular/core';
import { ApplicationModel, ApplicationVersions } from 'app/common/models/common.model';
import { BackEndCommunicationService } from 'app/common/services/beComm.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  constructor(
    private backEndCommunicationService: BackEndCommunicationService,
    private http: HttpClient
  ) {}
  /* PUBLIC */
  public getAllApplicationsP = (): Observable<any> => {
    return this.backEndCommunicationService.getResourceObservable('/applications?filter=public');
  };
  public getApplicationByIdP = (url: string): Observable<any> => {
    return this.backEndCommunicationService.getResourceObservable(`/applications/public/${url}`);
  };
  /* PRIVATE */
  public getAllApplications = (): Observable<ApplicationModel[]> => {
    return this.backEndCommunicationService.getResourceObservable('/applications');
  };
  public getApplicationById = (id: string): Observable<ApplicationModel> => {
    return this.backEndCommunicationService.getResourceObservable(`/applications/${id}`);
  };
  public getApplicationVersionById = (
    id: string,
    versionId: string
  ): Observable<ApplicationVersions> => {
    return this.backEndCommunicationService.getResourceObservable(
      `/applications/${id}/versions/${versionId}`
    );
  };
  createDeal(data): Observable<any> {
    return this.backEndCommunicationService.postResourceObservable('/deal', { json: data });
  }

  public addToLibrary = (id: string): Observable<ApplicationModel> => {
    const data = {};
    return this.backEndCommunicationService.postResourceObservable(
      `/applications/${id}/lastversion`,
      data
    );
  };
  public sendLastVersion = (applicationId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const data = {};
      this.backEndCommunicationService
        .postResource(`/applications/${applicationId}/lastversion`, data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject('Unknown error creating os version');
        });
    });
  };

  getLandingsList(): Observable<any> {
    return this.backEndCommunicationService.getResourceObservable('/landings');
  }
  getAllApplicationsLanding = (landingId): Observable<any> => {
    return this.backEndCommunicationService.getResourceObservable(`/landings/${landingId}`);
  };
}
