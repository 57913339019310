export const environment = {
  production: false,
  development: true,
  hmr: false,
  test: false,
  apiUrl: window['env']['apiUrl'] || 'default',
  auth_server: window['env']['auth_server'] || 'default',
  keycloak_realm: window['env']['keycloak_realm'] || 'default',
  panelUrl: window['env']['panelUrl'] || 'default',
  marketUrl: window['env']['marketUrl'] || 'default',
  helpdesk: window['env']['helpdesk'] || 'default'
  //,academyUrl: window['env']['academyUrl'] || 'default'
  //,analytics_id: window['env']['analytics_id'] || 'default'
  //,tag_manager: window['env']['tag_manager'] || 'default'
};
