<div class="{{ theme }} {{ className }}">
  <div *ngIf="isLoading" class="{{ theme }} spinner">
    <mat-spinner class="{{ theme }}" color="purple" diameter="150" strokeWidth="10"></mat-spinner>
  </div>
  <ng-container *ngIf="dataApplication && !isLoading">
    <div class="{{ theme }} {{ className }}__header">
      <img
        [src]="dataApplication.icon"
        alt="app icon"

        class="{{ theme }} {{ className }}__header-logo-app" />

      <div class="{{ theme }} {{ className }}__header-col title">
        <h2
          class="{{ theme }} {{ className }}__header-name-app"
          matTooltip="{{ title }}"
          id="{{utilsService.removeSpacesAndInsertScript(fakeTitle !== '' ? 'title-'+ fakeTitle : 'title-'+ dataApplication.name)}}"
          matTooltipClass="{{ theme }} custom-tooltip"
          matTooltipPosition="above">
          {{ fakeTitle !== '' ? fakeTitle : dataApplication.name }}
        </h2>
        <div
          *ngIf="width > 760"
          [innerHtml]="
            fakeDescription !== ''
              ? (fakeDescription | safe : 'html')
              : (dataApplication.shortDescription | safe : 'html')
          "
          id="{{className}}__header-text-app}}"
          class="{{ theme }} {{ className }}__header-text-app"
          matTooltip="{{ shortDescription }}"
          matTooltipClass="{{ theme }} custom-tooltip"
          matTooltipPosition="above"></div>
      </div>
      <div class="{{ theme }} {{ className }}__header-col button">
        <button
          (click)="contact(dataApplication._id, typeModal)"
          *ngIf="showContactButton"
          [disabled]="disabledButton"
          color="purple"
          id="contact-button"
          mat-flat-button>
          <mat-icon class="material-icons-outline">contact_support</mat-icon>
          {{ labels.requestInfo }}
        </button>

        <button
          (click)="addToLibrary(dataApplication._id, typeModal)"
          *ngIf="showAddToLibraryButton"
          [disabled]="disabledButton"
          color="purple"
          id="add-to-library-button"
          mat-flat-button>
          <mat-icon class="material-icons-outline">book</mat-icon>
          {{ labels.addToLibrary }}
        </button>

        <button
          *ngIf="showAlreadyInLibraryButton"
          [disabled]="disabledButton"
          color="primary-10"
          id="already-in-library-button"
          mat-flat-button>
          <mat-icon class="material-icons-outline">book</mat-icon>
          {{ labels.alredyInLibrary }}
        </button>
      </div>
    </div>
    <div
      class="{{ theme }} {{ className }}__subheader">
      <ul class="{{ theme }} {{ className }}__subheader-list">
        <li
          *ngIf="dataApplication.versions?.length > 0 && dataApplication.versions[lastVersion].name&& dataApplication.type=== 0"
          class="{{ theme }} {{ className }}__subheader-list-item">
          <span class="{{ theme }} {{ className }}__subheader-list-item-label">
            {{ labels.version }}
          </span>
          <span class="{{ theme }} {{ className }}__subheader-list-item-value" id="version-name">
            {{ dataApplication.versions[lastVersion].name }}
          </span>
        </li>

        <li
          *ngIf="dataApplication.developer?.length > 0 && dataApplication.developer"
          class="{{ theme }} {{ className }}__subheader-list-item">
          <span class="{{ theme }} {{ className }}__subheader-list-item-label" id="version-publisher">
            {{ labels.publisher }}
          </span>
          <span
            class="{{ theme }} {{ className }}__subheader-list-item-value"
            id="version-developer">
            {{ dataApplication.developer }}
          </span>
        </li>

        <li
          *ngIf="
            dataApplication.versions?.length > 0 && dataApplication.versions[lastVersion].created
          "
          class="{{ theme }} {{ className }}__subheader-list-item">
          <span class="{{ theme }} {{ className }}__subheader-list-item-label" id="version-date">
            {{ labels.date }}
          </span>
          <span class="{{ theme }} {{ className }}__subheader-list-item-value" id="version-created">
            {{ dataApplication.versions[lastVersion].created | date : 'MM/dd/YYYY' }}
          </span>
        </li>
        <li
          *ngIf="dataApplication.versions?.length > 0 && dataApplication.versions[lastVersion].size && dataApplication.type=== 0"
          class="{{ theme }} {{ className }}__subheader-list-item">
          <span class="{{ theme }} {{ className }}__subheader-list-item-label" >{{
            labels.size
          }}</span>
          <span class="{{ theme }} {{ className }}__subheader-list-item-value" id="version-size">
            {{ dataApplication.versions[lastVersion].size | convertBytes }}
          </span>
        </li>
      </ul>
    </div>

    <div class="{{ theme }} {{ className }}__wrapper">
      <ul class="{{ theme }} {{ className }}__list-image">
        <li
          *ngFor="let carouselImage of dataApplication.pictures; trackBy: identify"
          class="{{ theme }} {{ className }}__list-image-item">
          <img
            [src]="carouselImage"
            alt="app img"
            class="{{ theme }} {{ className }}__list-image-item-image" />
        </li>
      </ul>
    </div>
    <div class="{{ theme }} {{ className }}__categories">
      <ul class="{{ theme }} {{ className }}__categories-list">
        <ng-container *ngFor="let category of dataApplication.categories; trackBy: identify">
          <li *ngIf="category.type === 0" class="{{ theme }} {{ className }}__categories-list-item">
            <div
              [id]="utilsService.removeSpacesAndInsertScript(category.name)"
              class="{{ theme }} {{ className }}__categories-list-item-shape">
              <img
                *ngIf="category.icon.toString() === 'ai.svg'"
                alt="AI"
                class="material-icons-outlined {{ theme }} {{
                  className
                }}__categories-list-item-img"
                src="assets/images/icons/ai_white.svg" />

              <mat-icon
                *ngIf="category.icon.toString() !== 'ai.svg'"
                class="material-icons-outlined {{ theme }} {{
                  className
                }}__categories-list-item-icon">
                {{ category.icon }}
              </mat-icon>
              <span id="{{utilsService.removeSpacesAndInsertScript(category.name)}}" class="{{ theme }} {{ className }}__categories-list-item-text">
                {{ category.name }}
              </span>
            </div>
          </li>
        </ng-container>
      </ul>
      <ul class="{{ theme }} {{ className }}__categories-list">
        <li *ngIf="showFeatured" class="{{ theme }} {{ className }}__categories-list-item">
          <div
            TooltipTouchGestures="auto"
            class="{{ theme }} {{ className }}__categories-list-item-shape"
            matTooltip="{{ categoryFeatured.name }}"
            matTooltipClass="{{ theme }} custom-tooltip"
            matTooltipPosition="above">
            <mat-icon
              class="material-icons-outlined {{ theme }} {{
                className
              }}__categories-list-item-icon">
              {{ categoryFeatured.icon }}
            </mat-icon>
            <span class="{{ theme }} {{ className }}__categories-list-item-text">
              {{ categoryFeatured.name }}
            </span>
          </div>
        </li>
        <li *ngIf="showMostUsed" class="{{ theme }} {{ className }}__categories-list-item">
          <div
            TooltipTouchGestures="auto"
            class="{{ theme }} {{ className }}__categories-list-item-shape"
            matTooltip=" {{ categoryMostPurchased.name }}"
            matTooltipClass="{{ theme }} custom-tooltip"
            matTooltipPosition="above">
            <mat-icon
              class="material-icons-outlined {{ theme }} {{
                className
              }}__categories-list-item-icon">
              {{ categoryMostPurchased.icon }}
            </mat-icon>
            <span class="{{ theme }} {{ className }}__categories-list-item-text">
              {{ categoryMostPurchased.name }}
            </span>
          </div>
        </li>

        <li *ngIf="showBarbara" class="{{ theme }} {{ className }}__categories-list-item">
          <div
            TooltipTouchGestures="auto"
            class="{{ theme }} {{ className }}__categories-list-item-shape special"
            matTooltip=" {{ categorySpecial.name }}"
            matTooltipClass="{{ theme }} custom-tooltip"
            matTooltipPosition="above">
            <ng-container *ngIf="categorySpecial.icon.includes('b.svg')">
              <img
                alt="Barbara"
                class="{{ theme }} {{ className }}__categories-list-item-img"
                src="assets/images/icons/b_white.svg" />
            </ng-container>
            <ng-container *ngIf="!categorySpecial.icon.includes('b.svg')">
              <mat-icon
                class="material-icons-outlined {{ theme }} {{
                  className
                }}__categories-list-item-text">
                {{ categorySpecial.icon }}
              </mat-icon>
            </ng-container>
            <span class="{{ theme }} {{ className }}__categories-list-item-text">
              {{
                categorySpecial.name.includes('Barbara ')
                  ? (categorySpecial.name | replace : 'Barbara ' : ' ')
                  : categorySpecial.name
              }}
            </span>
          </div>
        </li>
      </ul>
    </div>
    <div class="{{ theme }} {{ className }}__architectures">
      <ul
        *ngIf="
          dataApplication.versions?.length > 0 &&
          dataApplication.versions[lastVersion].architectures.length > 0 && dataApplication.type=== 0
        "
        class="{{ theme }} {{ className }}__architectures-list">
        <li
          *ngFor="
            let architecture of dataApplication.versions[lastVersion].architectures;
            trackBy: identify
          "
          [id]="utilsService.removeSpacesAndInsertScript(architecture)"
          class="{{ theme }} {{ className }}__architectures-list-item {{ architecture }}">
          {{ architecture | architecture }}
        </li>
      </ul>
    </div>
    <div class="{{ theme }} {{ className }}__tabs">
      <mat-tab-group
        #tabList
        (selectedTabChange)="selectedTabChange($event)"
        class="no-padding"
        dynamicHeight>
        <mat-tab [label]="labels.description" id="app-description">
          <div class="{{ theme }} {{ className }}__description">
            <div
              [innerHtml]="
                dataApplication.longDescription !== ''
                  ? dataApplication.longDescription
                  : (labels.noDescriptionFound | safe : 'html')
              "
              class="{{ theme }} {{ className }}__description-text"></div>
          </div>
        </mat-tab>
        <mat-tab *ngIf="readme !== '' && dataApplication.type=== 0" [label]="labels.technicalNotes" id="app-technical-notes">
          <div class="{{ theme }} {{ className }}__readme">
            <app-readme-md [readme]="readme"></app-readme-md>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-container>

  <div
    *ngIf="categoryFeatured !== undefined && arrayApplicationFeatured !== undefined"
    class="{{ theme }} {{ className }}__category">
    <div class="{{ theme }} {{ className }}__category-wrapper">
      <h3 class="{{ theme }} {{ className }}__category-title">
        <mat-icon class="{{ theme }} {{ className }}__category-title-icon">{{
          categoryFeatured.icon
        }}</mat-icon>

        <span class="{{ theme }} {{ className }}__category-title-text">{{
          categoryFeatured.name
        }}</span>
      </h3>
    </div>
    <p
      *ngIf="categoryFeatured.applications.length === 0"
      class="{{ theme }} {{ className }}__category-list-no-data">
      {{ labels.noAppsFound }}
    </p>
    <ul
      *ngIf="categoryFeatured.applications.length > 0"
      class="{{ theme }} {{ className }}__category-list">
      <ng-container *ngFor="let app of arrayApplicationFeatured; trackBy: identify; let i = index">
        <ng-container *ngIf="app.enabled">
          <li
            *ngIf="width >= 3800 ? i <= 4 : width < 3800 && width >= 1800 ? i <= 3 : i <= 2"
            class="{{ theme }} {{ className }}__category-list-item">
            <app-card-vertical
              (moreButtonEvent)="onClickMoreButton(app._id)"
              *ngIf="app.enabled"
              [_data]="app"
              [categoryFeaturedId]="categoryFeaturedId"
              [categoryFeatured]="categoryFeatured"
              [categoryMostPurchasedId]="categoryMostPurchasedId"
              [categoryMostPurchased]="categoryMostPurchased"
              [categoryPurchasedId]="categoryPurchasedId"
              [categoryPurchased]="categoryPurchased"
              [categorySpecialId]="categorySpecialId"
              [categorySpecial]="categorySpecial"
              [isDesktop]="isDesktop"
              [showText]="true"
              [theme]="theme"
              [width]="width"></app-card-vertical>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</div>