import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CategoryModel } from 'app/common/models/common.model';

@Injectable({
  providedIn: 'root'
})
export class CategoriesStoreService {
  categoryList = new BehaviorSubject([]);
  stateMenu = new BehaviorSubject('');
  featuredCategoryId = new BehaviorSubject('');
  featuredCategory = new BehaviorSubject({} as CategoryModel);
  purchasedCategoryId = new BehaviorSubject('');
  purchasedCategory = new BehaviorSubject({} as CategoryModel);
  mostPurchasedCategoryId = new BehaviorSubject('');
  mostPurchasedCategory = new BehaviorSubject({} as CategoryModel);
  specialCategoryId = new BehaviorSubject('');
  specialCategory = new BehaviorSubject({} as CategoryModel);

  setCategoryList(category) {
    this.categoryList.next(category);
  }
  getCategoryList(): any {
    return this.categoryList.asObservable();
  }

  setStateMenu(state) {
    this.stateMenu.next(state);
  }
  getStateMenu(): any {
    return this.stateMenu.asObservable();
  }

  /* Featured Category */
  setFeaturedCategoryId = (id: string): void => {
    this.featuredCategoryId.next(id);
  };
  getFeaturedCategoryId(): BehaviorSubject<string> {
    return this.featuredCategoryId;
  }
  setFeaturedCategory(category: CategoryModel) {
    this.featuredCategory.next(category);
  }
  getFeaturedCategory(): BehaviorSubject<CategoryModel> {
    return this.featuredCategory;
  }

  /* Purchased Category */
  setPurchasedCategoryId = (id: string): void => {
    this.purchasedCategoryId.next(id);
  };
  getPurchasedCategoryId(): BehaviorSubject<string> {
    return this.purchasedCategoryId;
  }
  setPurchasedCategory(category: CategoryModel) {
    this.purchasedCategory.next(category);
  }
  getPurchasedCategory(): BehaviorSubject<CategoryModel> {
    return this.purchasedCategory;
  }

  /* Most Used Category */
  setMostPurchasedCategoryId = (id: string): void => {
    this.mostPurchasedCategoryId.next(id);
  };
  getMostPurchasedCategoryId(): BehaviorSubject<string> {
    return this.mostPurchasedCategoryId;
  }
  setMostPurchasedCategory(category: CategoryModel) {
    this.mostPurchasedCategory.next(category);
  }
  getMostPurchasedCategory(): BehaviorSubject<CategoryModel> {
    return this.mostPurchasedCategory;
  }
  /* Special Category */
  setSpecialCategoryId = (id: string): void => {
    this.specialCategoryId.next(id);
  };
  getSpecialCategoryId(): BehaviorSubject<string> {
    return this.specialCategoryId;
  }
  setSpecialCategory(category: CategoryModel) {
    this.specialCategory.next(category);
  }
  getSpecialCategory(): BehaviorSubject<CategoryModel> {
    return this.specialCategory;
  }
}
