import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-readme-md',
  templateUrl: './readme-md.component.html',
  styleUrls: ['./readme-md.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ReadmeMdComponent implements OnDestroy {
  @Input() readme: string;

  ngOnDestroy() {}
}
