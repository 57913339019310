<div *ngIf="isLoading" class="{{ theme }} spinner">
  <mat-spinner color="purple" diameter="150" strokeWidth="10" class="{{ theme }}"></mat-spinner>
</div>
<app-application-detail
  *ngIf="!isLoading"
  [dataApplication]="dataApplication"
  [disabledButton]="disabledButton"
  [showContactButton]="showContactButton"
  [showAddToLibraryButton]="showAddToLibraryButton"
  [showAlreadyInLibraryButton]="showAlreadyInLibraryButton"
  [typeModal]="typeModal"
  (emitAddToLibrary)="addToLibrary($event)"
  [categoriesList]="categoriesList"
  (emitContact)="contact($event)"
  (emitClickMore)="onClickMoreButton($event)"
  [categoryPurchasedId]="categoryPurchasedId"
  [categoryMostPurchasedId]="categoryMostPurchasedId"
  [categoryFeaturedId]="categoryFeaturedId"
  [categorySpecialId]="categorySpecialId"
  [categoryFeatured]="categoryFeatured"
  [categorySpecial]="categorySpecial"
  [categoryPurchased]="categoryPurchased"
  [categoryMostPurchased]="categoryMostPurchased"
  [arrayApplicationFeatured]="arrayApplicationFeatured"
  [labels]="labels"></app-application-detail>
