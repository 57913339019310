<div class="{{ theme }} {{ className }}">
  <app-header
    (closeMenuEcosystemEmitter)="closeMenuEcosystem()"
    (closeMenuEmitter)="closeMenu($event)"
    (closeMenuUserEmitter)="closeMenuUser()"
    (closeSessionEmitter)="closeSession()"
    (openMenuEcosystemEmitter)="openMenuEcosystem()"
    (openMenuEmitter)="openMenu($event)"
    (openMenuUserEmitter)="openMenuUser()"
    (openWindowEmitter)="openWindow($event.url, $event.target)"
    [className]="className"
    [isCompanyAdmin]="isCompanyAdmin"
    [isDesktop]="isDesktop"
    [showMenuEcosystem]="showMenuEcosystem"
    [showMenuUser]="showMenuUser"
    [showMenu]="showMenu"
    [theme]="theme"
    [userAvatar]="userAvatar"
    [userName]="userName"
    [width]="width"
    class="{{ theme }} {{ className }}__header"></app-header>
  <mat-sidenav-container
    *ngIf="!isLoading"
    [hasBackdrop]="!isDesktop"
    class="{{ theme }} {{ className }}__container">
    <mat-sidenav
      #sidenav
      [class.main-layout__menu--active]="showMenu"
      [mode]="isDesktop ? 'push' : 'over'"
      [style.bottom]="isDesktop ? '0' : 'auto'"
      [style.top]="isDesktop ? '61px' : '59px'"
      class="{{ theme }} {{ className }}__menu"
      disableClose
      fixedInViewport="true"
      fixedTopGap="59">
      <app-menu
        (closeMenuEmitter)="closeMenu($event)"
        (closeSessionEmitter)="closeSession()"
        [dataCategories]="dataCategories"
        [isAdmin]="isAdmin"
        [isDesktop]="isDesktop"
        [showMenu]="showMenu"
        [theme]="theme"
        [width]="width"></app-menu>
    </mat-sidenav>
    <mat-sidenav-content
      [class.main-layout__content--active]="showMenu"
      class="{{ theme }} {{ className }}__content">
      <div
        (click)="showMenuEcosystem = false; showMenu = false; showMenuUser = false"
        *ngIf="showMenuEcosystem || showMenuUser"
        class="{{ theme }} {{ className }}__overlay"></div>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>