import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  url = new BehaviorSubject('');

  // eslint-disable-next-line complexity
  constructor() {
    if (localStorage.getItem('userPermission')) {
      sessionStorage.setItem('userPermission', localStorage.getItem('userPermission'));
    }
    if (localStorage.getItem('devicePermission')) {
      sessionStorage.setItem('devicePermission', localStorage.getItem('devicePermission'));
    }
    if (localStorage.getItem('deplyPermission')) {
      sessionStorage.setItem('deplyPermission', localStorage.getItem('deplyPermission'));
    }
    if (localStorage.getItem('name')) {
      sessionStorage.setItem('name', localStorage.getItem('name'));
    }
    if (localStorage.getItem('email')) {
      sessionStorage.setItem('email', localStorage.getItem('email'));
    }
    if (localStorage.getItem('avatarUri')) {
      sessionStorage.setItem('avatarUri', localStorage.getItem('avatarUri'));
    }
    if (localStorage.getItem('isAdmin')) {
      sessionStorage.setItem('isAdmin', localStorage.getItem('isAdmin'));
    }
    if (localStorage.getItem('authToken')) {
      sessionStorage.setItem('authToken', localStorage.getItem('authToken'));
    }
    if (localStorage.getItem('deviceKnown')) {
      sessionStorage.setItem('deviceKnown', localStorage.getItem('deviceKnown'));
    }
    if (localStorage.getItem('deviceId')) {
      sessionStorage.setItem('deviceId', localStorage.getItem('deviceId'));
    }
  }

  public retrieveAuthToken = (): string => {
    return sessionStorage.getItem('authToken');
  };
  public storePersistentlyAuthToken = (token: string): void => {
    localStorage.setItem('authToken', token);
  };

  public storeAuthToken = (token: string): void => {
    sessionStorage.setItem('authToken', token);
  };

  public retrieveIsAdmin = (): boolean => {
    return sessionStorage.getItem('isAdmin') === 'true';
  };
  public storeIsAdmin = (isAdmin: boolean): void => {
    localStorage.setItem('isAdmin', isAdmin.toString());
    sessionStorage.setItem('isAdmin', isAdmin.toString());
  };

  public retrieveAvatarUri = (): string => {
    return sessionStorage.getItem('avatarUri');
  };

  setUrl = (param: string): void => {
    localStorage.setItem('ai', param.toString());
  };

  getUrl() {
    return localStorage.getItem('ai');
  }

  setCategoryId(id: string) {
    localStorage.setItem('ci', id.toString());
  }

  getCategoryId() {
    return localStorage.getItem('ci');
  }

  public getDeviceId = (): string => {
    return localStorage.getItem('deviceId');
  };
  public getDeviceName = (): string => {
    return localStorage.getItem('deviceName');
  };

  public getListDevicesSession = (): any => {
    const data = sessionStorage.getItem('listDevices');
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  };
  public saveListDevicesSession = (listDevices): void => {
    sessionStorage.setItem('listDevices', JSON.stringify(listDevices));
  };

  setDeviceId(mode: any) {
    localStorage.setItem('deviceId', mode.toString());
  }

  setDeviceName(mode: any) {
    localStorage.setItem('deviceName', mode.toString());
  }

  setViewTable(mode: boolean) {
    localStorage.setItem('viewTable', mode.toString());
  }

  getViewTable() {
    return localStorage.getItem('viewTable');
  }

  public storeAvatarUri = (avatarUri: string): void => {
    localStorage.setItem('avatarUri', avatarUri);
    sessionStorage.setItem('avatarUri', avatarUri);
  };

  public retrieveEmail = (): string => {
    return sessionStorage.getItem('email');
  };

  public storeEmail = (email: string): void => {
    localStorage.setItem('email', email);
    sessionStorage.setItem('email', email);
  };

  public retrieveName = (): string => {
    return sessionStorage.getItem('name');
  };

  public storeName = (name: string): void => {
    localStorage.setItem('name', name);
    sessionStorage.setItem('name', name);
  };

  public retrieveDeplyPermission = (): boolean => {
    return sessionStorage.getItem('deplyPermission') === 'true';
  };

  public storeDeplyPermission = (deplyPermission: boolean): void => {
    localStorage.setItem('deplyPermission', deplyPermission.toString());
    sessionStorage.setItem('deplyPermission', deplyPermission.toString());
  };

  public retrieveDevicePermission = (): boolean => {
    return sessionStorage.getItem('devicePermission') === 'true';
  };

  public storeDevicePermission = (devicePermission: boolean): void => {
    localStorage.setItem('devicePermission', devicePermission.toString());
    sessionStorage.setItem('devicePermission', devicePermission.toString());
  };

  public retrieveUserPermission = (): boolean => {
    return sessionStorage.getItem('userPermission') === 'true';
  };

  public storeUserPermission = (userPermission: boolean): void => {
    localStorage.setItem('userPermission', userPermission.toString());
    sessionStorage.setItem('userPermission', userPermission.toString());
  };

  public retrieveListPagination = (view?: string): string => {
    if (view === undefined) {
      view = '';
    }
    return sessionStorage.getItem('listPagination' + view);
  };

  public storeListPagination = (pagination: string, view?: string): void => {
    if (view === undefined) {
      view = '';
    }
    sessionStorage.setItem('listPagination' + view, pagination);
  };

  public retrieveListSorting = (view?: string): string => {
    if (view === undefined) {
      view = '';
    }
    return sessionStorage.getItem('listSorting' + view);
  };

  public storeListSorting = (sorting: string, view?: string): void => {
    if (view === undefined) {
      view = '';
    }
    sessionStorage.setItem('listSorting' + view, sorting);
  };

  public retrieveListTags = (): string[] => {
    return JSON.parse(sessionStorage.getItem('listTags')) || [];
  };

  setInputType(mode: any) {
    localStorage.setItem('typeInput', mode.toString());
  }

  clearInputType() {
    localStorage.removeItem('typeInput');
  }

  getInputType() {
    return localStorage.getItem('typeInput');
  }

  public storeListTags = (tags: string[]): void => {
    sessionStorage.setItem('listTags', JSON.stringify(tags));
  };

  public retrieveListSelection = (): string[] => {
    return JSON.parse(sessionStorage.getItem('listSelection')) || [];
  };

  public storeListSelection = (selection: string[]): void => {
    sessionStorage.setItem('listSelection', JSON.stringify(selection));
  };

  public retrieveDeviceKnown = (): boolean => {
    return JSON.parse(sessionStorage.getItem('deviceKnown')) === true;
  };

  public retrievePersistentlyDeviceKnown = (): boolean => {
    return JSON.parse(localStorage.getItem('deviceKnown')) === true;
  };

  public storePersistentlyDeviceKnown = (deviceKnown: boolean): void => {
    localStorage.setItem('deviceKnown', JSON.stringify(deviceKnown).toString());
  };

  public storeDeviceKnown = (deviceKnown: boolean): void => {
    sessionStorage.setItem('deviceKnown', JSON.stringify(deviceKnown).toString());
  };

  public removeDeviceKnown = (): void => {
    localStorage.removeItem('deviceKnown');
  };

  public retrieveQrImage = (): string => {
    return JSON.parse(sessionStorage.getItem('qrImage'));
  };

  public storeQrImage = (qrImage: string): void => {
    sessionStorage.setItem('qrImage', JSON.stringify(qrImage).toString());
  };

  public removeQrImage = (): void => {
    sessionStorage.removeItem('qrImage');
  };

  public removelocalStorageData = (): void => {
    localStorage.removeItem('userPermission');
    localStorage.removeItem('devicePermission');
    localStorage.removeItem('deplyPermission');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('avatarUri');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('authToken');
    localStorage.removeItem('u');
    sessionStorage.removeItem('userPermission');
    sessionStorage.removeItem('devicePermission');
    sessionStorage.removeItem('deplyPermission');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('avatarUri');
    sessionStorage.removeItem('isAdmin');
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('listPagination');
    sessionStorage.removeItem('listSorting');
    sessionStorage.removeItem('listTags');
    sessionStorage.removeItem('listSelection');
    sessionStorage.removeItem('qrImage');
    sessionStorage.removeItem('deviceKnown');
  };
  public retrieveSelectedTab = (): string => {
    return localStorage.getItem('selectedTab');
  };
  public removeSelectedTab = (): void => {
    localStorage.removeItem('selectedTab');
  };
  public storeSelectedTab = (indexTab: string): void => {
    localStorage.setItem('selectedTab', indexTab);
  };

  setApplicationId(id: string) {
    localStorage.setItem('applicationId', id);
  }

  getApplicationId() {
    return localStorage.getItem('applicationId');
  }

  /*
  public setStoreCategories = (data): void => {
    console.log(data);
    localStorage.setItem('categories', JSON.stringify(data));
  };
  public getStoreCategories = (): any => {
    return localStorage.getItem('categories');
  };
  public removeStoreCategories = (): void => {
    localStorage.removeItem('categories');
  };

  public setPurchasedCategoryId = (id: string): void => {
    localStorage.setItem('PurId', id);
  };
  public setLoveCategoryId= (id: string): void => {
    localStorage.setItem('LovId', id);
  };
  public setLoveCategory = (data): void => {
    localStorage.setItem('LovData', JSON.stringify(data));
  };
  public getPurchasedCategoryId = (): string => {
    return localStorage.getItem('PurId');
  };
  public getLoveCategoryId= (): string => {
    return localStorage.getItem('LovId');
  };

  public getLoveCategory = (): string => {;
    return JSON.parse(localStorage.getItem('LovData'));
  };
  public removePurchasedCategoryId = (): void => {
    localStorage.removeItem('PurId');
  };
  public removeLoveCategoryId = (): void => {
    localStorage.removeItem('LovId');
  };
*/
}
