import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryModel, CommonLabels } from 'app/common/models/common.model';
import { commonLabels } from 'app/common/common.labels';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { StorageService } from '../../../common/services/storage.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { UtilsService } from '../../../common/services/utils.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.scss']
})
export class CategoryDetailComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() theme: string;
  @Input() isDesktop: boolean;
  @Input() width: number;
  @Input() context: string;
  @Input() isLoading: boolean;
  @Input() showText: boolean;
  @Input() categoryPurchasedId: string;
  @Input() categoryMostPurchasedId: string;
  @Input() categoryFeaturedId: string;
  @Input() categorySpecialId: string;
  @Input() dataCategory: CategoryModel;
  @Input() categoryFeatured: CategoryModel;
  @Input() categorySpecial: CategoryModel;
  @Input() categoryPurchased: CategoryModel;
  @Input() categoryMostPurchased: CategoryModel;
  @Input() arrayApplicationFeatured: any = [];
  className = 'app-category-detail';
  labels: CommonLabels = commonLabels;
  title: string;
  constructor(
    private readonly router: Router,
    private storageService: StorageService,
    private scrollDispatcher: ScrollDispatcher,
    public utilsService: UtilsService
  ) {}

  ngOnInit() {
    //console.log(this.dataCategory);
    this.goTop();
  }
  ngOnChanges(): void {
    this.goTop();
  }
  ngAfterViewInit(): void {
    this.goTop();

    /* Ver si vale para la paginación
      //console.log(this.scrollDispatcher);
      this.scrollDispatcher.scrolled()
        .subscribe((event: any) => {
          console.log(event);
         // console.log(event.elementRef.nativeElement.scrollTop);
          //console.log(event.elementRef.nativeElement.scrollHeight);
        });
      */
  }

  goTop() {
    document.getElementsByClassName('mat-drawer-content')[0].scrollTo(0, 0);
  }
  onClickMoreButton(id: string): void {
    this.storageService.setUrl('application-detail/' + id);
    this.router.navigate(['application-detail', id]);
  }
  identify(index, item) {
    return index;
  }
  ngOnDestroy(): void {}
}
