<div class="{{ theme }} {{ className }}__container">
  <!-- HUBSPOT FORM ICON CLOSE MODAL -->
  <mat-icon
    (click)="closeModal($event, 'close')"
    *ngIf="showCloseModal"
    class="{{ theme }} {{ className }}__close"
    id="modal-close">
    close
  </mat-icon>
  <div class="{{ theme }} {{ className }}__header">
    <ng-container>
      <img
        *ngIf="data.imgHeader !== '' && data.imgHeader !== undefined"
        alt="Marketplace icon"
        class="{{ theme }} {{ className }}__header-icon"
        src="{{ data.imgHeader }}" />
      <div class="{{ theme }} {{ className }}__header-wrapper">
        <h2 class="{{ theme }} {{ className }}__header-title">{{ data.title }}</h2>
        <div
          *ngIf="data.textHeader !== '' && data.textHeader !== undefined"
          [innerHtml]="data.textHeader | safe : 'html'"
          class="{{ theme }} {{ className }}__header-text"></div>
        <div
          *ngIf="data.textHeader1 !== '' && data.textHeader1 !== undefined"
          [innerHtml]="data.textHeader1 | safe : 'html'"
          class="{{ theme }} {{ className }}__header-text"></div>
        <a (click)="login()" class="{{ theme }} {{ className }}__header-text-link">
          {{ data.linkHeader }}
        </a>
        <div
          *ngIf="data.textHeader2 !== '' && data.textHeader2 !== undefined"
          [innerHtml]="data.textHeader2 | safe : 'html'"
          class="{{ theme }} {{ className }}__header-text"></div>
      </div>
    </ng-container>
  </div>

  <mat-dialog-content class="{{ theme }} {{ className }}__body">
    <!-- MESSAGE FOR CONFIRMATION & ERROR MODALS -->
    <ng-container *ngIf="data.bodyText">
      <div
        [innerHtml]="data.bodyText | safe : 'html'"
        class="{{ theme }} {{ className }}__body-content"></div>
    </ng-container>
    <!-- HUBSPOT FORM -->
    <ng-container *ngIf="data.type === 'contact-form-public'">
      <app-hubspot (submitedFormEmitter)="onSubmitForm($event)"></app-hubspot>
    </ng-container>
    <!-- APPLICATIONS TYPE 0 (InstallableApplication) -->
    <ng-container *ngIf="data.originalData?.type === 0 && data.type !== 'confirm'">
      <!-- WITH PRICE -->
      <ng-container
        *ngIf="data.originalData.price.length > 0 && data.originalData.price[0].value > 0">
        <div class="{{ theme }} {{ className }}__wrapper-price-no-margin">
          <span class="{{ theme }} {{ className }}__price">
            <div class="{{ theme }} {{ className }}__price-wrapper">
              <mat-icon class="{{ theme }} {{ className }}__price-icon">sell</mat-icon>
              <span class="{{ theme }} {{ className }}__price-text">
                {{ data.originalData.price[0].value }} € / {{ data.originalData.price[0].unit }} *
              </span>
            </div>
          </span>
          <button
            (click)="emitAction($event, 'startTrial')"
            class="{{ theme }} {{ className }}__button"
            color="purple"
            id="start-trial-button"
            mat-flat-button>
            <mat-icon class="{{ theme }} {{ className }}__button-icon">flag</mat-icon>
            {{ labels.startTrial }}
          </button>
        </div>
        <p class="{{ theme }} {{ className }}__price-after-first-month">
          {{ labels.afterFirstMonth }}
        </p>
      </ng-container>
      <!-- FREE -->
      <ng-container
        *ngIf="data.originalData.price.length === 0 || data.originalData.price[0].value === 0">
        <div class="{{ theme }} {{ className }}__wrapper-price">
          <span class="{{ theme }} {{ className }}__price">
            <div class="{{ theme }} {{ className }}__price-wrapper">
              <mat-icon class="{{ theme }} {{ className }}__price-icon">sell</mat-icon>
              <span class="{{ theme }} {{ className }}__price-text">{{ labels.free }}</span>
            </div>
          </span>
          <button
            (click)="emitAction($event, 'addToPanel')"
            class="{{ theme }} {{ className }}__button"
            color="purple"
            id="add-to-library-2"
            mat-flat-button>
            <mat-icon class="{{ theme }} {{ className }}__button-icon">book</mat-icon>
            {{ labels.addToLibrary }}
          </button>
        </div>
      </ng-container>
    </ng-container>
    <!-- APPLICATIONS TYPE 1 (Application) -->
    <ng-container *ngIf="data.originalData?.type === 1 && data.type !== 'confirm'">
      <!-- CONTACT -->
      <ng-container>
        <div class="{{ theme }} {{ className }}__wrapper-price">
          <span class="{{ theme }} {{ className }}__price"> </span>
          <button
            (click)="emitAction($event, 'contact-form-public')"
            class="{{ theme }} {{ className }}__button"
            color="purple"
            id="contact-button"
            mat-flat-button>
            <mat-icon class="{{ theme }} {{ className }}__button-icon">contact_support</mat-icon>
            {{ labels.contactUs }}
          </button>
        </div>
      </ng-container>
    </ng-container>
    <!-- TERMS & CONDITIONS -->
    <ng-container
      *ngIf="data.terms?.length > 0 && showTerms && data.type !== 'contact-form-public'">
      <div class="{{ theme }} {{ className }}__tabs">
        <mat-tab-group
          #tabList
          (selectedTabChange)="selectedTabChange($event)"
          class="no-padding"
          dynamicHeight>
          <mat-tab [label]="labels.termsAndConditions" id="modal-terms">
            <ng-container *ngIf="data.terms !== ''">
              <div
                [innerHtml]="data.terms | safe : 'html'"
                class="{{ theme }} {{ className }}__terms-and-conditions"></div>
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="showFooter" align="end" class="{{ theme }} {{ className }}__footer">
    <ng-container *ngFor="let button of data.buttons; trackBy: identify; let i = index">
      <!-- HUBSPOT FORM SUBMITTED CLOSE BUTTON MODAL -->
      <ng-container *ngIf="button.action === 'close' && showButtonClose">
        <div class="{{ theme }} {{ className }}__footer-wrapper">
          <button
            (click)="closeModal($event, button.action)"
            [color]="button.color"
            class="{{ theme }} {{ className }}__button"
            id="{{ button.buttonId }}"
            mat-button>
            <mat-icon class="{{ theme }} {{ className }}__button-icon">{{ button.icon }}</mat-icon>
            {{ button.text | uppercase }}
          </button>
        </div>
      </ng-container>
      <!-- REST BUTTONS MODAL -->
      <ng-container *ngIf="button.action !== 'close'">
        <div class="{{ theme }} {{ className }}__footer-wrapper">
          <button
            (click)="emitAction($event, button.action)"
            [color]="button.color"
            class="{{ theme }} {{ className }}__button"
            id="{{ button.buttonId }}"
            mat-flat-button>
            <mat-icon class="{{ theme }} {{ className }}__button-icon">{{ button.icon }}</mat-icon>
            {{ button.text | uppercase }}
          </button>
        </div>
      </ng-container>
    </ng-container>
  </mat-dialog-actions>
</div>
