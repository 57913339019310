import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { commonLabels } from 'app/common/common.labels';
import { CommonLabels } from 'app/common/models/common.model';
import { MatTabGroup } from '@angular/material/tabs';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent implements OnInit, OnDestroy {
  labels: CommonLabels = commonLabels;
  className = 'app-modal-message';
  theme = 'marketplace-default';
  @ViewChild('tabList', { static: false }) tabList: MatTabGroup;
  tabSelectedIndex: number = 0;

  showTerms: boolean = false;
  showCloseModal: boolean = false;
  showFooter: boolean = false;
  showButtonClose: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalMessageComponent>,
    private readonly keycloak: KeycloakService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    if (this.data.type === 'contact-form-public') {
      this.showCloseModal = true;
    }
    if (this.data.showFooter !== undefined) {
      this.showFooter = this.data.showFooter;
    }
    if (this.data.showButtonClose !== undefined) {
      this.showButtonClose = this.data.showButtonClose;
    }
    if (
      this.data.terms !== undefined &&
      this.data.terms !== null &&
      this.data.terms !== '' &&
      this.data.terms.length > 0
    ) {
      this.showTerms = true;
    }
  }

  closeModal($event, action) {
    this.dialogRef.close({
      $event,
      action,
      element: this.data.originalData,
      width: this.data.width
    });
  }

  emitAction($event, action) {
    this.dialogRef.close({
      $event,
      action,
      element: this.data.originalData,
      width: this.data.width
    });
  }

  onSubmitForm(event) {
    this.data.title = 'Contact request received';
    this.data.textHeader = '';
    this.showTerms = false;
    this.showCloseModal = false;
    this.showFooter = true;
    this.showButtonClose = true;
    this.dialogRef.close(event);
  }

  selectedTabChange(event) {
    this.tabSelectedIndex = event.index;
  }

  identify(index, item) {
    return index;
  }

  public login() {
    this.keycloak.login();
  }

  ngOnDestroy(): void {}
}
