<app-main-layout
  *ngIf="userLogIn && !isLoading"
  [isDesktop]="isDesktop"
  [width]="width"
  [theme]="theme"
  [showMenu]=""
  [showMenuEcosystem]=""
  [showMenuUser]=""
  [userName]="userName"
  [userAvatar]="userAvatar"
  [isAdmin]="isAdmin"
  [isCompanyAdmin]="isCompanyAdmin"></app-main-layout>

<app-main-layout-public
  *ngIf="!userLogIn && !isLoading"
  [isDesktop]="isDesktop"
  [width]="width"
  [theme]="theme"
  [showMenu]=""
  [showMenuEcosystem]=""></app-main-layout-public>
