import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor() {}
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  randomNumber(data, numberRandom) {
    const max = data.length - 1;
    const min = 0;
    for (var i = 0; numberRandom.length < 5; i++) {
      let select = Math.floor(Math.random() * (max - min) + min);
      if (numberRandom.includes(select)) {
        select = Math.floor(Math.random() * (max - min) + min);
      } else {
        numberRandom.push(select);
      }
    }
    return numberRandom;
  }
  conversorString(texto: any) {
    return Buffer.from(texto, 'base64').toString();
  }

  conversorNumberBase64(texto: any) {
    return Buffer.from(texto, 'base64');
  }
  autoGrowTextArea(textarea: any) {
    textarea.target.style.height = '0px';
    textarea.target.style.height = textarea.target.scrollHeight + 25 + 'px';
  }
  conversorBase64(texto: any) {
    return Buffer.from(texto).toString('base64');
  }
  removeSpacesAndInsertScript(string) {
    return string.replace(/[\s_.]/g, '-');
  }
}
