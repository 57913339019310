import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BackEndCommunicationService {
  private serverUrl = '';

  constructor(private http: HttpClient, private router: Router) {
    this.serverUrl = environment.apiUrl;
  }
  public postResource(uri: string, data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'content-type': 'application/json'
        })
      };
      this.http
        .post(this.serverUrl + uri, data, httpOptions)
        .toPromise()
        .then((response: any) => {
          if (response) {
            resolve(response.response);
          } else {
            resolve('OK');
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getResourceObservable(uri: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json; charset=utf-8'
      })
    };
    return this.http.get(this.serverUrl + uri, httpOptions).pipe(
      map((response: any) => {
        response = response.response;
        return response;
      })
    );
  }

  public deleteResourceObservable(uri: string, data?: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json'
      }),
      body: data
    };
    return this.http.delete(this.serverUrl + uri, httpOptions).pipe(
      map((response: any) => {
        console.log(response);
        response = response.response;
        return response;
      })
    );
  }

  public postResourceObservable(uri: string, data: object): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json'
      })
    };
    return this.http.post(this.serverUrl + uri, data, httpOptions).pipe(
      map((response: any) => {
        response = response.response;
        return response;
      })
    );
  }
  public putResourceObservable(uri: string, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json'
      })
    };
    return this.http.put(this.serverUrl + uri, data, httpOptions).pipe(
      map((response: any) => {
        response = response.response;
        return response;
      })
    );
  }

  public postZipResourceObservable(uri: string, data: object): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({})
    };
    return this.http.post(this.serverUrl + uri, data, httpOptions).pipe(
      map((response: any) => {
        response = response.response;
        return response;
      })
    );
  }
  public postZipResourceWithProgress(uri: string, formData: FormData): Observable<HttpEvent<any>> {
    const httpOptions = {
      headers: new HttpHeaders({}),
      reportProgress: true
    };

    const request = new HttpRequest('POST', this.serverUrl + uri, formData, httpOptions);

    return this.http.request(request);
  }
  public putZipResourceObservable(uri: string, data: object): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({})
    };
    return this.http.put(this.serverUrl + uri, data, httpOptions).pipe(
      map((response: any) => {
        response = response.response;
        return response;
      })
    );
  }
}
