import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, fromEvent } from 'rxjs';
import { pluck, map, debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResizeWindowService {
  height$: Observable<number>;
  width$: Observable<number>;
  isDesktop$: Observable<boolean>;

  constructor() {
    const windowProperties$ = new BehaviorSubject(this.getWindowProperties());

    this.height$ = windowProperties$.pipe(pluck('height'), debounceTime(300));
    this.width$ = windowProperties$.pipe(pluck('width'), debounceTime(300));
    this.isDesktop$ = windowProperties$.pipe(pluck('isDesktop'), debounceTime(300));

    fromEvent(window, 'resize').pipe(map(this.getWindowProperties)).subscribe(windowProperties$);
  }

  getWindowProperties() {
    return {
      height: window.innerHeight,
      width: window.innerWidth,
      isDesktop: window.innerWidth >= 1024
    };
  }

  getPartsDate(date: Date): number[] {
    return [
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    ];
  }

  //Para que espere a que termine el evento sea el que sea
  waitForFinalEvent = (function () {
    let timers = {};
    return function (callback, ms, uniqueId) {
      if (!uniqueId) {
        uniqueId = "Don't call this twice without a uniqueId";
      }
      if (timers[uniqueId]) {
        clearTimeout(timers[uniqueId]);
      }
      timers[uniqueId] = setTimeout(callback, ms);
    };
  })();
}
