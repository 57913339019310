<div class="{{ theme }} {{ className }}" #scrollPage>
  <div *ngIf="isLoading" class="{{ theme }} spinner">
    <mat-spinner color="purple" diameter="150" strokeWidth="10" class="{{ theme }}"></mat-spinner>
  </div>
  <app-category-detail
    *ngIf="dataCategory && !isLoading"
    [theme]="theme"
    [isDesktop]="isDesktop"
    [width]="width"
    [isLoading]="isLoading"
    [categoryPurchasedId]="categoryPurchasedId"
    [categoryMostPurchasedId]="categoryMostPurchasedId"
    [categoryFeaturedId]="categoryFeaturedId"
    [categorySpecialId]="categorySpecialId"
    [categoryFeatured]="categoryFeatured"
    [categorySpecial]="categorySpecial"
    [categoryPurchased]="categoryPurchased"
    [categoryMostPurchased]="categoryMostPurchased"
    [arrayApplicationFeatured]="arrayApplicationFeatured"
    [showText]="showText"
    [dataCategory]="dataCategory">
  </app-category-detail>
</div>
