<div class="{{ className }}__menu-wrapper">
  <ul [ngClass]="showMenu ? 'menu__menu-list menu__menu-list--active' : 'menu__menu-list'">
    <li
      *ngFor="let option of contentMenu; trackBy: identify"
      class="{{ className }}__menu-list-item"
      routerLinkActive="{{ className }}__menu-list-item--active">
      <a
        (click)="closeMenu($event, option.url)"
        [routerLink]="[option.url]"
        [target]="option.externalUrl ? '_blank' : '_self'"
        class="{{ className }}__menu-list-item-link"
        id="{{ utilsService.removeSpacesAndInsertScript(option.title) }}">
        <div class="{{ className }}__menu-list-item-shape">
          <ng-container *ngIf="option.icon.toString() === 'b.svg'">
            <svg
              class="{{ className }}__menu-list-item-shape-svg"
              fill="none"
              height="17"
              viewBox="0 0 16 17"
              width="16"
              xmlns="http://www.w3.org/2000/svg">
              <path
                class="fill"
                d="M8.7105 4.95876H8.12835H8.12971C7.97694 5.71718 7.78168 6.68712 7.63886 7.40938H8.20338C8.88767 7.40938
  9.30711 7.85051 9.30711 8.53525V12.844C9.29355 13.5608 8.88812 14.0223 8.17355 14.0354L6.99931 14.0354C6.77965 14.0354 6.60157 13.8573 6.60157 13.6376V7.40938V4.95876V0.804181C6.60157 0.583616 6.4027 0.451186 6.20428 0.517175L4.01852 1.20147C3.93038 1.22362 3.8418 1.3122 3.8418 1.42249V14.0354C3.8418 15.691 4.70281 16.5077 6.31457 16.5077H7.57287L7.57423 16.5063C7.57603 16.5068 8.09626 16.5077 8.09807 16.5077C10.5039 16.5077 12.0886 15.1531 12.0886 12.9398V8.66814C12.0886 6.46023 10.7864 4.95876 8.7105 4.95876Z"
                fill="#938F99" />
              <path
                class="fill"
                d="M6.60157 4.95876V7.40938L7.63841 7.40938C7.78214 6.68215 7.97287 5.73435 8.12925 4.95876H6.60157Z"
                fill="#777777"
                fill-opacity="0.5" />
            </svg>
          </ng-container>
          <ng-container *ngIf="option.icon.toString() === 'ai.svg'">
            <svg
              class="{{ className }}__menu-list-item-shape-svg"
              fill="none"
              height="17"
              viewBox="0 0 18 17"
              width="18"
              xmlns="http://www.w3.org/2000/svg">
              <path
                class="stroke"
                d="M13.8 14.9H15.4V2.1H13.8M13.8 14.9H10.6M13.8 14.9V16.5M10.6 14.9H7.4M10.6 14.9V16.5M7.4 14.9H4.2M7.4 14.9V16.5M4.2 14.9H2.6V2.1H4.2M4.2 14.9V16.5M4.2 2.1V0.5M4.2 2.1H7.4M7.4 2.1V0.5M7.4 2.1H10.6M10.6 2.1V0.5M10.6 2.1H13.8M13.8 2.1V0.5M15.4 3.7H17M15.4 13.3H17M15.4 6.9H17M15.4 10.1H17M2.6 3.7H1M2.6 13.3H1M2.6 6.9H1M2.6 10.1H1M4.2 3.7H13.8V13.3H4.2V3.7Z"
                stroke="#938F99"
                stroke-linecap="round" />
              <path
                class="fill"
                d="M6.91612 10.9002H5.86157L7.46839 6.24561H8.73657L10.3411 10.9002H9.28657L8.12066 7.30924H8.0843L6.91612 10.9002ZM6.85021 9.07061H9.34112V9.83879H6.85021V9.07061Z"
                fill="#938F99" />
              <path
                class="fill"
                d="M11.8854 6.24561V10.9002H10.9013V6.24561H11.8854Z"
                fill="#938F99" />
            </svg>
          </ng-container>
          <ng-container
            *ngIf="option.icon.toString() !== 'b.svg' && option.icon.toString() !== 'ai.svg'">
            <mat-icon
              TooltipTouchGestures="auto"
              class="material-icons-outlined icon {{ className }}__menu-list-item-shape-icon"
              matTooltip="{{ option.title }}"
              matTooltipClass="{{ theme }} custom-tooltip"
              matTooltipPosition="right">
              {{ option.icon }}
            </mat-icon>
          </ng-container>
        </div>
        <span
          *ngIf="this.showMenu"
          class="{{ className }}__menu-list-item-text"
          id="{{ utilsService.removeSpacesAndInsertScript(option.title) + '-text' }}">
          {{ option.title }}
        </span>
      </a>
    </li>
  </ul>
</div>