<!--<div id="hubspotFormContactUs" ></div>-->
<div *ngIf="objectForm" class="{{ theme }} {{ className }}__form">
  <form [formGroup]="contactUs" novalidate>
    <div *ngFor="let input of objectForm" class="{{ theme }} {{ className }}__form-field-wrapper">
      <mat-form-field appearance="outline" class="onlyplaceholder">
        <input
          [autocomplete]="input.autocomplete"
          [formControlName]="input.formControl"
          [id]="input.id"
          [maxlength]="input.maxLength"
          [name]="input.name"
          [placeholder]="input.placeholder"
          [required]="input.required"
          matInput />
      </mat-form-field>
    </div>
    <div>
      <mat-checkbox
        [checked]="contactUs.get('communications').value"
        [id]="'communications'"
        [value]="contactUs.get('communications').value"
        color="purple"
        formControlName="communications"
        labelPosition="after"
        >I agree to receive communications from Barbara
      </mat-checkbox>
      <mat-checkbox
        [checked]="false"
        [id]="'conditions'"
        [value]="contactUs.get('conditions').value"
        color="purple"
        formControlName="conditions"
        labelPosition="after"
        required>
        <p>
          I have read and accept the
          <a
            class="enlace"
            href="https://barbaraiot.com/terms-and-conditions"
            rel="noopener"
            target="_blank"
            >Terms and Conditions</a
          >
          and the
          <a
            class="enlace"
            href="https://barbaraiot.com/en/politica-de-privacidad/"
            rel="noopener"
            target="_blank"
            >Privacy Policy*</a
          >.
        </p>
      </mat-checkbox>
    </div>
    <div class="{{ theme }} {{ className }}__submit">
      <button
        (click)="submitForm()"
        [disabled]="!contactUs.valid"
        color="purple"
        id="submit"
        mat-flat-button>
        SUBMIT
      </button>
    </div>
  </form>
</div>