<mat-toolbar (keydown.escape)="closeMenu()" class="{{ theme }} {{ className }}__header">
  <div class="{{ theme }} {{ className }}__header-container-left">
    <div class="{{ theme }} {{ className }}__header-toggle-menu">
      <mat-icon
        (click)="openMenu()"
        *ngIf="!showMenu"
        TooltipTouchGestures="auto"
        class="material-icons-outlined"
        id="show-main-menu"
        matTooltip="{{ labels.showMenu }}"
        matTooltipClass="{{ theme }} custom-tooltip"
        >menu
      </mat-icon>
      <mat-icon
        (click)="closeMenu()"
        *ngIf="showMenu"
        TooltipTouchGestures="auto"
        class="material-icons-outlined"
        id="hide-main-menu"
        matTooltip="{{ labels.hideMenu }}"
        matTooltipClass="{{ theme }} custom-tooltip">
        close
      </mat-icon>
    </div>
    <a class="{{ theme }} {{ className }}__header-logo" routerLink="home">
      <h1 class="hidden">Barbaraiot MarketPlace</h1>
      <div class="{{ theme }} {{ className }}__header-logo-wrapper" id="header-logo-private">
        <img
          alt="Icono marketplace"
          class="{{ theme }} {{ className }}__header-logo-icon"
          src="{{
            isDesktop
              ? 'assets/images/logos/ecosystem_market.svg'
              : 'assets/images/logos/market_avatar.svg'
          }}" />
      </div>
    </a>
  </div>
  <div *ngIf="false" class="{{ theme }} {{ className }}__header-container-center"></div>
  <div class="{{ theme }} {{ className }}__header-container-right">
    <div class="{{ className }}__container-feedback">
      <img
        src="assets/icons/feedback.svg"
        alt="Get support"
        (click)="openUrl(environment.helpdesk, 'barbara')"
        TooltipTouchGestures="on"
        class="{{ className }}__icon-ecosystem"
        matTooltip="Get support"
        matTooltipClass="custom-tooltip" />
    </div>
    <div class="{{ className }}__container-ecosystem">
      <mat-icon
        (click)="openMenuEcosystem()"
        *ngIf="!showMenuEcosystem"
        TooltipTouchGestures="on"
        class="{{ className }}__icon-ecosystem"
        id="show-ecosystem-menu"
        matTooltip="{{ labels.showEcosystem }}"
        matTooltipClass="{{ theme }} custom-tooltip">
        dialpad
      </mat-icon>
      <mat-icon
        (click)="closeMenuEcosystem()"
        *ngIf="showMenuEcosystem"
        TooltipTouchGestures="on"
        class="{{ className }}__icon-ecosystem-close"
        id="hide-ecosystem-menu"
        matTooltip="{{ labels.hideEcosystem }}"
        matTooltipClass="{{ theme }} custom-tooltip">
        close
      </mat-icon>
      <ng-container *ngIf="showMenuEcosystem">
        <div class="{{ className }}__eco-container">
          <ul class="{{ className }}__menu-eco">
            <li
              (click)="openWindow('https://barbara.tech', 'barbara')"
              class="{{ className }}__menu-eco-item"
              id="barbara-link">
              <a class="{{ className }}__menu-eco-link">
                <img
                  alt="ico barbara"
                  class="{{ className }}__menu-eco-icon"
                  src="assets/images/logos/ecosystem_barbara.svg" />
              </a>
            </li>
            <li
              (click)="openWindow(environment.panelUrl, 'barbaraPanel')"
              class="{{ className }}__menu-eco-item"
              id="panel-link">
              <a class="{{ className }}__menu-eco-link">
                <img
                  alt="ico panel"
                  class="{{ className }}__menu-eco-icon"
                  src="assets/images/logos/ecosystem_panel.svg" />
              </a>
            </li>
            <li
              (click)="openWindow(environment.marketUrl, 'barbaraMarket')"
              *ngIf="false"
              class="{{ className }}__menu-eco-item"
              id="market-link">
              <a class="{{ className }}__menu-eco-link">
                <img
                  alt="ico market"
                  class="{{ className }}__menu-eco-icon"
                  src="assets/images/logos/ecosystem_market.svg" />
              </a>
            </li>
            <li
              (click)="openWindow('https://academy.barbaraiot.com/', 'barbaraAcademy')"
              class="{{ className }}__menu-eco-item"
              id="academy-link">
              <a class="{{ className }}__menu-eco-link">
                <img
                  alt="ico academy"
                  class="{{ className }}__menu-eco-icon"
                  src="assets/images/logos/ecosystem_academy.svg" />
              </a>
            </li>

            <li
              (click)="openWindow('https://studio.barbaraiot.com/', 'barbaraStudio')"
              *ngIf="false"
              class="{{ className }}__menu-eco-item"
              id="studio-link">
              <a class="{{ className }}__menu-eco-link">
                <img
                  alt="ico studio"
                  class="{{ className }}__menu-eco-icon"
                  src="assets/images/logos/ecosystem_studio.svg" />
              </a>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
    <div class="{{ theme }} {{ className }}__header-container-right-link">
      <div
        (click)="showMenuUser ? closeMenuUser() : openMenuUser()"
        TooltipTouchGestures="on"
        class="{{ theme }} {{ className }}__header-container-right"
        id="{{ className }}__{{ showMenuUser ? 'hide-user-menu' : 'show-user-menu' }}"
        matTooltip="{{ showMenuUser ? labels.hideMenuUser : labels.showMenuUser }}"
        matTooltipClass="{{ theme }} custom-tooltip">
        <div class="{{ className }}__header-container-right-avatar">
          <img
            [src]="userAvatar === '' ? defaultAvatar : userAvatar"
            alt="avatar"
            id="{{ className }}__user-avatar-image" />
        </div>
        <div
          *ngIf="isDesktop"
          class="{{ className }}__header-container-right-name"
          id="{{ className }}__user-name">
          {{ userName }}
        </div>
      </div>

      <ng-container *ngIf="showMenuUser">
        <div class="{{ className }}__eco-container user">
          <ul class="{{ className }}__menu-eco user">
            <li class="{{ className }}__menu-eco-item">
              <a
                (click)="navigationEditUser()"
                class="{{ className }}__menu-eco-link"
                id="{{ className }}__link-edit-profile">
                <mat-icon class="material-icons-sharp">person_outline</mat-icon>
                <span class="{{ className }}__menu-eco-item-text">Edit profile</span>
              </a>
            </li>

            <li
              *ngIf="isCompanyAdmin"
              class="{{ className }}__menu-eco-item"
              routerLink="/users/userlist">
              <a
                (click)="navigationListUserCompany()"
                class="{{ className }}__menu-eco-link"
                id="{{ className }}__link-edit-organization">
                <mat-icon class="material-icons-sharp">business</mat-icon>
                <span class="{{ className }}__menu-eco-item-text">Edit organization</span>
              </a>
            </li>

            <li
              (click)="closeSession()"
              class="{{ className }}__menu-eco-item"
              id="{{ className }}__link-close-session">
              <a class="{{ className }}__menu-eco-link">
                <mat-icon>logout</mat-icon>
                <span class="{{ className }}__menu-eco-item-text">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</mat-toolbar>