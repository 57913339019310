<div class="{{ theme }} {{ className }}" id="home-private">
  <div *ngIf="isLoading" class="{{ theme }} spinner">
    <mat-spinner color="purple" diameter="150" strokeWidth="10" class="{{ theme }}"></mat-spinner>
  </div>
  <ng-container *ngIf="!isLoading">
    <!-- Vertical App -->
    <ng-container
      *ngIf="categoryFeatured !== undefined && categoryFeatured.applications?.length > 0">
      <div class="{{ theme }} {{ className }}__category">
        <ul
          class="{{ theme }} {{ className }}__category-list"
          *ngIf="categoryFeatured.applications.length > 0">
          <ng-container
            *ngFor="let app of arrayApplicationFeatured; trackBy: identify; let i = index">
            <ng-container *ngIf="app.enabled">
              <li
                *ngIf="width >= 3800 ? i <= 4 : width < 3800 && width >= 1800 ? i <= 3 : i <= 2"
                class="{{ theme }} {{ className }}__category-list-item">
                <app-card-vertical
                  [theme]="theme"
                  [isDesktop]="isDesktop"
                  [width]="width"
                  [categoryFeaturedId]="categoryFeaturedId"
                  [categorySpecialId]="categorySpecialId"
                  [categoryPurchasedId]="categoryPurchasedId"
                  [categoryMostPurchasedId]="categoryMostPurchasedId"
                  [categoryFeatured]="categoryFeatured"
                  [categorySpecial]="categorySpecial"
                  [categoryPurchased]="categoryPurchased"
                  [categoryMostPurchased]="categoryMostPurchased"
                  [showText]="true"
                  (moreButtonEvent)="onClickMoreButton(app._id)"
                  [_data]="app"></app-card-vertical>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </ng-container>
    <!-- Horizontal App -->
    <ng-container *ngIf="dataCategory.length > 0">
      <div
        class="{{ theme }} {{ className }}__category"
        *ngFor="let category of dataCategory; trackBy: identify">
        <ng-container *ngIf="category.applications?.length > 0">
          <div class="{{ theme }} {{ className }}__category-wrapper">
            <h3
              (click)="clickCategorySeeAll(category._id)"
              id="title-category-{{ utilsSevice.removeSpacesAndInsertScript(category.name) }}"
              class="{{ theme }} {{ className }}__category-title">
              <ng-container *ngIf="category.icon.includes('ai.svg')">
                <img
                  class="{{ theme }} {{ className }}__category-title-img"
                  src="assets/images/icons/ai_blue.svg"
                  alt="{{ category.name }}" />
              </ng-container>
              <ng-container *ngIf="category.icon.includes('b.svg')">
                <img
                  class="{{ theme }} {{ className }}__category-title-img"
                  src="assets/images/icons/b_blue.svg"
                  alt="{{ category.name }}" />
              </ng-container>
              <ng-container
                *ngIf="!category.icon.includes('ai.svg') && !category.icon.includes('b.svg')">
                <mat-icon class="{{ theme }} {{ className }}__category-title-icon">{{
                  category.icon
                }}</mat-icon>
              </ng-container>
              <span class="{{ theme }} {{ className }}__category-title-text">
                {{ category.name }}
              </span>
            </h3>
            <button
              *ngIf="width >= 760"
              (click)="clickCategorySeeAll(category._id)"
              id="button-category-{{ utilsSevice.removeSpacesAndInsertScript(category.name) }}"
              mat-stroked-button
              color="transparent"
              class="{{ theme }} {{ className }}__category-button">
              <mat-icon class="material-icons-outline">add</mat-icon>
              {{ labels.seeAll }}
            </button>
            <button
              *ngIf="width < 760"
              (click)="clickCategorySeeAll(category._id)"
              id="button-mobile-category-{{ utilsSevice.removeSpacesAndInsertScript(category.name) }}"
              mat-stroked-button
              color="transparent"
              class="{{ theme }} {{ className }}__category-button"
              TooltipTouchGestures="auto"
              matTooltipPosition="above"
              matTooltip="{{ labels.seeAll }}"
              matTooltipClass="{{ theme }} custom-tooltip">
              <mat-icon class="material-icons-outline" style="margin-right: -4px">add</mat-icon>
            </button>
          </div>
          <ul
            class="{{ theme }} {{ className }}__category-list"
            *ngIf="category.applications.length > 0">
            <ng-container
              *ngFor="let app of category.applications; trackBy: identify; let i = index">
              <ng-container *ngIf="app.enabled && !app.isPublic">
                <li
                  *ngIf="width >= 3800 ? i <= 4 : width < 3800 && width >= 1800 ? i <= 3 : i <= 2"
                  class="{{ theme }} {{ className }}__category-list-item">
                  <app-card-horizontal
                    [theme]="theme"
                    [isDesktop]="isDesktop"
                    [width]="width"
                    [categoryFeaturedId]="categoryFeaturedId"
                    [categorySpecialId]="categorySpecialId"
                    [categoryPurchasedId]="categoryPurchasedId"
                    [categoryMostPurchasedId]="categoryMostPurchasedId"
                    [categoryFeatured]="categoryFeatured"
                    [categorySpecial]="categorySpecial"
                    [categoryPurchased]="categoryPurchased"
                    [categoryMostPurchased]="categoryMostPurchased"
                    (moreButtonEvent)="onClickMoreButton(app._id)"
                    [_data]="app"></app-card-horizontal>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
