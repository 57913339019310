import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'architectures'
})
export class ArchitecturesPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    const architectures = [];
    value.forEach((element) => {
      switch (element) {
        case 'aarch64':
          architectures.push(' ARM64/V8');
          break;
        case 'x86_64':
          architectures.push(' AMD64');
          break;
        case 'armv7':
          architectures.push(' ARM/V7');
          break;
        case 'tpu':
          architectures.push(' TPU ENABLED');
          break;
        case 'gpu':
          architectures.push(' GPU ENABLED');
          break;
      }
    });

    return architectures;
  }
}

@Pipe({
  name: 'architecture'
})
export class ArchitecturePipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    switch (value) {
      case 'aarch64':
        value = 'ARM64/V8';
        break;
      case 'x86_64':
        value = 'AMD64';
        break;
      case 'armv7':
        value = 'ARM/V7';
        break;
      case 'tpu':
        value = 'TPU ENABLED';
        break;
      case 'gpu':
        value = 'GPU ENABLED';
        break;
    }

    return value;
  }
}
