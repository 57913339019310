import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedCommonModule } from '../../common/shared-common.module';
import { ApplicationDetailComponent } from './application-detail/application-detail.component';
import { ViewDetailComponent } from './view-detail.component';

const routes: Routes = [
  {
    path: 'application-detail/:id',
    component: ViewDetailComponent
  }
];
@NgModule({
  imports: [CommonModule, SharedCommonModule, RouterModule.forChild(routes)],
  declarations: [ApplicationDetailComponent, ViewDetailComponent],
  exports: [RouterModule, CommonModule, SharedCommonModule, ApplicationDetailComponent]
})
export class ApplicationModule {}
