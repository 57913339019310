<div class="{{ theme }} {{ className }}">
  <h3 class="{{ theme }} {{ className }}__title">{{ _data.title1 }}</h3>
  <h3 *ngIf="_data.title2 !== undefined" class="{{ theme }} {{ className }}__title last">
    {{ _data.title2 }}
  </h3>
  <p class="{{ theme }} {{ className }}__text">{{ _data.text }}</p>
  <button
    (click)="clickButtonContactUs($event)"
    id="{{ _data.buttonId }}"
    mat-button
    class="{{ theme }} {{ className }}__button"
    color="dark-blue">
    {{ _data.button }}
  </button>
  <img
    *ngIf="isDesktop"
    class="{{ theme }} {{ className }}__image"
    [src]="_data.srcImg"
    alt="banner image" />
  <img
    *ngIf="!isDesktop"
    class="{{ theme }} {{ className }}__image"
    [src]="_data.srcImgMobile"
    alt="banner image" />
</div>
