import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedCommonModule } from '../../common/shared-common.module';
import { CategoryComponent } from './category.component';
import { CategoryDetailComponent } from './category-details/category-detail.component';

const routes: Routes = [
  {
    path: 'category-detail/:id',
    component: CategoryComponent
  }
];
@NgModule({
  imports: [CommonModule, SharedCommonModule, RouterModule.forChild(routes)],
  declarations: [CategoryComponent, CategoryDetailComponent],
  exports: [
    RouterModule,
    CommonModule,
    SharedCommonModule,
    CategoryComponent,
    CategoryDetailComponent
  ]
})
export class CategoryModule {}
