<div
  class="{{ theme }} {{ className }}"
  (click)="clickButtonMore(_data._id, $event)"
  id="card-horizontal-{{ utilsService.removeSpacesAndInsertScript(_data.name) }}">
  <div class="{{ theme }} {{ className }}__wrapper">
    <img class="{{ theme }} {{ className }}__icon" [src]="_data.icon" alt="app icon" />
    <h3
      id="card-horizontal-title"
      class="{{ theme }} {{ className }}__title"
      matTooltipPosition="above"
      matTooltip="{{ _data.name }}"
      matTooltipClass="{{ theme }} custom-tooltip">
      {{ fakeTitle !== '' ? fakeTitle : _data.name }}
    </h3>
    <mat-icon
      *ngIf="purchased"
      class="{{ theme }} {{ className }}__icon-purchased"
      matTooltipPosition="above"
      matTooltip="{{ labels.alredyInLibrary }}"
      matTooltipClass="{{ theme }} custom-tooltip"
      >book</mat-icon
    >
  </div>
  <div
    id="card-horizontal-description"
    class="{{ theme }} {{ className }}__text"
    matTooltipPosition="above"
    matTooltip="{{ shortDescriptionTooltip }}"
    matTooltipClass="{{ theme }} custom-tooltip"
    [innerHtml]="
      fakeDescription !== ''
        ? (fakeDescription | safe : 'html')
        : (_data.shortDescription | safe : 'html')
    ">
    {{ fakeDescription !== '' ? fakeDescription : _data.shortDescription }}
  </div>
  <ul class="{{ theme }} {{ className }}__tags-list">
    <li *ngIf="featured" class="{{ theme }} {{ className }}__tags-list-item">
      <div
        class="{{ theme }} {{ className }}__tags-list-item-shape"
        TooltipTouchGestures="auto"
        matTooltipPosition="above"
        matTooltip="{{ categoryFeatured.name }}"
        matTooltipClass="{{ theme }} custom-tooltip">
        <mat-icon class="material-icons-outlined {{ theme }} {{ className }}__tags-list-item-icon">
          {{ categoryFeatured.icon }}
        </mat-icon>
      </div>
    </li>
    <li *ngIf="mostPurchased" class="{{ theme }} {{ className }}__tags-list-item">
      <div
        class="{{ theme }} {{ className }}__tags-list-item-shape"
        TooltipTouchGestures="auto"
        matTooltipPosition="above"
        matTooltip="{{ categoryMostPurchased.name }}"
        matTooltipClass="{{ theme }} custom-tooltip">
        <mat-icon class="material-icons-outlined {{ theme }} {{ className }}__tags-list-item-icon">
          {{ categoryMostPurchased.icon }}
        </mat-icon>
      </div>
    </li>
    <li *ngIf="special" class="{{ theme }} {{ className }}__tags-list-item">
      <div
        class="{{ theme }} {{ className }}__tags-list-item-shape special"
        TooltipTouchGestures="auto"
        matTooltipPosition="above"
        matTooltip="{{ categorySpecial.name }}"
        matTooltipClass="{{ theme }} custom-tooltip">
        <ng-container *ngIf="categorySpecial.icon.includes('b.svg')">
          <img
            src="assets/images/icons/b_white.svg"
            alt="Barbara"
            class="{{ theme }} {{ className }}__tags-list-item-img" />
        </ng-container>
        <ng-container *ngIf="!categorySpecial.icon.includes('b.svg')">
          <mat-icon
            class="material-icons-outlined {{ theme }} {{ className }}__tags-list-item-icon">
            {{ categorySpecial.icon }}
          </mat-icon>
        </ng-container>
      </div>
    </li>
  </ul>
</div>
