import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertBytes' })
export class ConvertBytesPipe implements PipeTransform {
  transform(bytes) {
    let units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

    let i = 0;

    for (i; bytes > 1024; i++) {
      bytes /= 1024;
    }
    if (i === 0) {
      return bytes.toFixed(0) + ' ' + units[i];
    }
    return bytes.toFixed(1) + ' ' + units[i];
  }
}
