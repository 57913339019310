import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { commonLabels } from 'app/common/common.labels';
import { CategoryModel, CommonLabels } from 'app/common/models/common.model';
import { AuthService } from '../../../../common/services/auth.service';
import { StorageService } from '../../../../common/services/storage.service';
import { CategoriesService } from '../../../../common/services/categories.service';
import { CategoriesStoreService } from '../../../../common/services/category-observable.service';
import { ResizeWindowService } from '../../../../common/services/resize-window.service';
import { KeycloakService } from 'keycloak-angular';
import { SnackMessageService } from '../../../../common/services/snackMessage.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-main-layout',
  templateUrl: 'main-layout.component.html',
  styleUrls: ['main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  @Input() isDesktop: boolean;
  @Input() width: number;
  @Input() theme: string;
  @Input() showMenu? = true;
  @Input() showMenuEcosystem? = false;
  @Input() showMenuUser? = false;
  @Input() userName: string;
  @Input() userAvatar: string;
  @Input() isAdmin? = false;
  @Input() isCompanyAdmin? = false;
  @ViewChild('sidenav') sidenav: MatSidenav;

  className = 'main-layout';
  labels: CommonLabels = commonLabels;
  isLoading = false;
  categories: Observable<CategoryModel[]>;
  categoryFeaturedId;
  categoryPurchasedId;
  mostPurchasedCategoryId;
  categorySpecialId;
  dataCategories = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private readonly resizeWindowService: ResizeWindowService,
    private storageService: StorageService,
    private categoriesService: CategoriesService,
    private categoriesStoreService: CategoriesStoreService,
    private snackMessageService: SnackMessageService,
    private readonly keycloak: KeycloakService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.showMenu = this.width > 600 ? true : false;
    this.categoriesStoreService.setStateMenu('close');
    this.categoriesService.getAllCategories().subscribe(
      (data) => {
        this.categoriesStoreService.setCategoryList(data);
        data.forEach((category) => {
          //console.log(category);
          switch (category.type) {
            case 1:
              //featured
              this.categoriesStoreService.setFeaturedCategoryId(category._id);
              this.categoriesStoreService.setFeaturedCategory(category);
              this.categoryFeaturedId = category._id;
              break;
            case 2:
              //purchased
              this.categoriesStoreService.setPurchasedCategoryId(category._id);
              this.categoriesStoreService.setPurchasedCategory(category);
              this.categoryPurchasedId = category._id;
              break;
            case 3:
              //most_purchased
              this.categoriesStoreService.setMostPurchasedCategoryId(category._id);
              this.categoriesStoreService.setMostPurchasedCategory(category);
              this.mostPurchasedCategoryId = category._id;
              break;
            case 4:
              //special
              this.categoriesStoreService.setSpecialCategoryId(category._id);
              this.categoriesStoreService.setSpecialCategory(category);
              this.categorySpecialId = category._id;
              break;
            default:
              //standard
              break;
          }
          this.dataCategories.push(category);
        });

        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
  }

  closeMenu($event): void {
    if ($event.action !== 'closeMenu') {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 100);
    } else {
      this.categoriesStoreService.setStateMenu('close');
      this.showMenu = false;
    }

    this.showMenuEcosystem = false;
    this.showMenuUser = false;
    this.sidenav.close();
  }

  openMenu($event): void {
    this.categoriesStoreService.setStateMenu('open');
    this.showMenu = true;
    this.showMenuEcosystem = false;
    this.showMenuUser = false;
    this.sidenav.open();
  }

  closeMenuEcosystem(): void {
    this.showMenuEcosystem = false;
    this.showMenuUser = false;
    this.sidenav.close();
  }

  openMenuEcosystem(): void {
    this.showMenuEcosystem = true;
    this.showMenuUser = false;
    this.sidenav.close();
  }

  closeMenuUser(): void {
    this.showMenuEcosystem = false;
    this.showMenuUser = false;
    this.sidenav.close();
  }

  openMenuUser(): void {
    this.showMenuEcosystem = false;
    this.showMenuUser = true;
    this.sidenav.close();
  }

  openWindow(url, target) {
    this.showMenuEcosystem = !this.showMenuEcosystem;
    window.open(url, '_blank');
  }

  closeSession(): void {
    this.logout();
    this.storageService.removelocalStorageData();
    this.authService.setUserLoggedIn(false);
    this.router.navigate(['/']);
  }

  public logout() {
    this.keycloak.logout();
  }

  identify(index, item) {
    return index;
  }

  ngOnDestroy(): void {}
}