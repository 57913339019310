import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { CommonLabels } from '../../../../common/models/common.model';
import { commonLabels } from '../../../../common/common.labels';
import { Router } from '@angular/router';
import { ResizeWindowService } from '../../../../common/services/resize-window.service';
import { CategoriesStoreService } from '../../../../common/services/category-observable.service';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../../../environments/environment';

@AutoUnsubscribe()
@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isDesktop: boolean;
  @Input() width: number;
  @Input() theme: string;
  @Input() showMenu? = true;
  @Input() showMenuEcosystem? = false;
  @Input() showMenuUser? = false;
  @Input() userName: string;
  @Input() userAvatar: string;
  @Input() isAdmin? = false;
  @Input() isCompanyAdmin? = false;
  @Output() closeMenuEmitter: EventEmitter<any> = new EventEmitter();
  @Output() openMenuEmitter: EventEmitter<any> = new EventEmitter();
  @Output() closeMenuEcosystemEmitter: EventEmitter<any> = new EventEmitter();
  @Output() openMenuEcosystemEmitter: EventEmitter<any> = new EventEmitter();
  @Output() closeSessionEmitter: EventEmitter<any> = new EventEmitter();
  @Output() openMenuUserEmitter: EventEmitter<any> = new EventEmitter();
  @Output() closeMenuUserEmitter: EventEmitter<any> = new EventEmitter();
  @Output() openWindowEmitter: EventEmitter<any> = new EventEmitter();
  className = 'header';
  labels: CommonLabels = commonLabels;
  defaultAvatar = '/assets/images/avatars/profile.jpg';
  protected readonly environment = environment;

  constructor(
    private router: Router,
    private categoriesStoreService: CategoriesStoreService,
    private readonly resizeWindowService: ResizeWindowService,
    private readonly keycloak: KeycloakService
  ) {}

  ngOnInit() {}

  openUrl(url: string, target?: string) {
    window.open(url, target !== undefined ? target : '_blank');
  }

  openMenu(): void {
    this.categoriesStoreService.setStateMenu('open');
    this.openMenuEmitter.emit('openMenu');
  }

  closeMenu(): void {
    this.categoriesStoreService.setStateMenu('close');
    this.closeMenuEmitter.emit({ action: 'closeMenu' });
  }

  openMenuEcosystem(): void {
    this.openMenuEcosystemEmitter.emit('openMenuEcosystem');
  }

  closeMenuEcosystem(): void {
    this.closeMenuEcosystemEmitter.emit('closeMenuEcosystem');
  }

  openMenuUser(): void {
    this.openMenuUserEmitter.emit('openMenuUser');
  }

  closeMenuUser(): void {
    this.closeMenuUserEmitter.emit('closeMenuUser');
  }

  closeSession(): void {
    this.closeSessionEmitter.emit('closeSession');
  }

  navigationEditUser() {
    window.open('' + `${environment.panelUrl}/account/profile`, 'barbaraPanel');
  }

  navigationListUserCompany() {
    window.open(`${environment.panelUrl}/users/userlist`, 'barbaraPanel');
  }

  openWindow(url, target) {
    this.openWindowEmitter.emit({ url: url, target: target });
  }

  public logout() {
    this.keycloak.logout();
  }

  ngOnDestroy() {}
}
