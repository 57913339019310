import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryModel, CommonLabels } from 'app/common/models/common.model';
import { commonLabels } from 'app/common/common.labels';
import { ResizeWindowService } from 'app/common/services/resize-window.service';
import { CategoriesService } from '../../common/services/categories.service';
import { CategoriesStoreService } from '../../common/services/category-observable.service';
import { StorageService } from '../../common/services/storage.service';
import { UtilsService } from '../../common/services/utils.service';
import { SnackMessageService } from '../../common/services/snackMessage.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnDestroy {
  theme = 'marketplace-default';
  className = 'app-category';
  isDesktop: boolean = false;
  width: number = 0;
  labels: CommonLabels = commonLabels;
  show = false;

  dataCategoryId;
  dataCategory: CategoryModel;
  categoryPurchasedId;
  categoryMostPurchasedId;
  categoryFeaturedId;
  categorySpecialId;
  categoryFeatured: CategoryModel = undefined;
  categorySpecial: CategoryModel = undefined;
  categoryMostPurchased: CategoryModel = undefined;
  categoryPurchased: CategoryModel = undefined;
  arrayApplicationFeatured: any = [];
  _temp;
  isLoading: boolean = true;
  showText: boolean = true;
  menuState: string = 'close';

  constructor(
    private resizeWindowService: ResizeWindowService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private storageService: StorageService,
    private categoriesStoreService: CategoriesStoreService,
    private categoriesService: CategoriesService,
    private snackMessageService: SnackMessageService,
    public utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    let url = this.storageService.getCategoryId();
    //console.log(url);
    this.dataCategoryId = url.split('/').pop();
    this.initSubscriptions();
  }

  initSubscriptions() {
    this.resizeWindowService.isDesktop$.subscribe(
      (value: boolean) => {
        this.isDesktop = value;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.resizeWindowService.width$.subscribe(
      (value: number) => {
        this.width = value;

        this.resizeWindowService.waitForFinalEvent(
          () => {
            this.width = value;
            this.isLoading = true;
            this.prepareDataCategory(this.dataCategoryId);
          },
          500,
          'loadingCategory'
        );
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getStateMenu().subscribe(
      (state) => {
        this.menuState = state;
        if (
          this.width === 1024 &&
          this.menuState === 'close' &&
          (this.dataCategory.type === 1 ||
            this.dataCategory.type === 3 ||
            this.dataCategory.type === 4)
        ) {
          this.showText = true;
        }
        if (
          this.width === 1024 &&
          this.menuState === 'open' &&
          (this.dataCategory.type === 1 ||
            this.dataCategory.type === 3 ||
            this.dataCategory.type === 4)
        ) {
          this.showText = false;
        }
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getPurchasedCategoryId().subscribe(
      (id: string) => {
        this.categoryPurchasedId = id;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getMostPurchasedCategoryId().subscribe(
      (id: string) => {
        this.categoryMostPurchasedId = id;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getFeaturedCategoryId().subscribe(
      (id: string) => {
        this.categoryFeaturedId = id;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getSpecialCategoryId().subscribe(
      (id: string) => {
        this.categorySpecialId = id;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getPurchasedCategory().subscribe(
      (category: CategoryModel) => {
        this.categoryPurchased = category;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getMostPurchasedCategory().subscribe(
      (category: CategoryModel) => {
        this.categoryMostPurchased = category;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getSpecialCategory().subscribe(
      (category: CategoryModel) => {
        this.categorySpecial = category;
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
    this.categoriesStoreService.getFeaturedCategory().subscribe(
      (category: CategoryModel) => {
        this.categoryFeatured = category;
        this.categoryFeatured.applications = this.categoryFeatured.applications.filter(
          (application) => {
            return application.isPublic === false;
          }
        );
        if (this.categoryFeatured.applications.length > 5) {
          this.randomApp();
        } else {
          this.arrayApplicationFeatured = this.categoryFeatured.applications;
        }
      },
      (error) => {
        this.isLoading = false;
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
  }

  prepareDataCategory(categoryId: string) {
    let _apps = [];
    this._temp = [];
    this.categoriesService.getCategoryById(categoryId).subscribe(
      (data: any) => {
        this.dataCategory = data;

        this.dataCategory.applications.forEach((app, index) => {
          if (!app.isPublic && app.enabled) {
            app.categories.forEach((id, index) => {
              if (id === this.categoryPurchasedId) {
                app.purchased = true;
              } else {
                app.purchased = false;
              }
            });
            _apps.push(app);
          }
        });
        this.dataCategory.applications = _apps;
        this.show = true;
        this.isLoading = false;
        window.scrollBy(0, -window.innerHeight);
      },
      (error) => {
        this.isLoading = false;
        this.router.navigate(['home']);
        this.snackMessageService.sendMessage(error.error.response.errorMessage);
      }
    );
  }

  randomApp() {
    let numberRandom = [];
    this.arrayApplicationFeatured = [];
    this.utilsService.randomNumber(this.categoryFeatured.applications, numberRandom);
    numberRandom.forEach((idx) => {
      this.arrayApplicationFeatured.push(this.categoryFeatured.applications[idx]);
    });
  }

  ngOnDestroy(): void {}
}
