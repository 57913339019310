import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackMessageService {
  constructor(private snackBar: MatSnackBar) {
  }

  sendMessage(message: string): void {
    this.snackBar.open(message, 'close', {
      duration: 10000
    });
  }
}
