<div
  class="{{ theme }} {{ className }}"
  (click)="clickButtonMore(_data.id, _data.name, $event)"
  id="card-{{ utilsService.removeSpacesAndInsertScript(_data.name) }}">
  <!--
  <img class="{{ theme }} {{ className }}__image" [src]="_data.pictures" alt="app image" />
  -->
  <div class="{{ theme }} {{ className }}__wrapper-image">
    <img class="{{ theme }} {{ className }}__image" [src]="_data.pictures[0]" alt="app image" />
    <ul class="{{ theme }} {{ className }}__tags-list">
      <li class="{{ theme }} {{ className }}__tags-list-item featured">
        <div
          *ngIf="featured"
          class="{{ theme }} {{ className }}__tags-list-item-shape featured"
          TooltipTouchGestures="auto"
          matTooltipPosition="above"
          matTooltip="{{ categoryFeatured.name }}"
          matTooltipClass="{{ theme }} custom-tooltip">
          <mat-icon
            class="material-icons-outlined {{ theme }} {{ className }}__tags-list-item-icon">
            {{ categoryFeatured.icon }}
          </mat-icon>
          <span
            class="{{ theme }} {{ className }}__tags-list-item-text"
            *ngIf="
              (width <= 1024 && showText) ||
              (menuState === 'close' && width > 1024 && width < 1280) ||
              width >= 1280
            ">
            {{ categoryFeatured.name }}
          </span>
        </div>
      </li>
      <li class="{{ theme }} {{ className }}__tags-list-item most-purchased">
        <div
          *ngIf="mostPurchased"
          class="{{ theme }} {{ className }}__tags-list-item-shape most-purchased"
          TooltipTouchGestures="auto"
          matTooltipPosition="above"
          matTooltip="{{ categoryMostPurchased.name }}"
          matTooltipClass="{{ theme }} custom-tooltip">
          <mat-icon
            class="material-icons-outlined {{ theme }} {{ className }}__tags-list-item-icon">
            {{ categoryMostPurchased.icon }}
          </mat-icon>
          <span
            class="{{ theme }} {{ className }}__tags-list-item-text"
            *ngIf="
              (width <= 1024 && showText) ||
              (menuState === 'close' && width > 1024 && width < 1280) ||
              width >= 1280
            ">
            {{ categoryMostPurchased.name }}
          </span>
        </div>
      </li>
      <li class="{{ theme }} {{ className }}__tags-list-item special">
        <div
          *ngIf="special"
          class="{{ theme }} {{ className }}__tags-list-item-shape special"
          TooltipTouchGestures="auto"
          matTooltipPosition="above"
          matTooltip="{{ categorySpecial.name }}"
          matTooltipClass="{{ theme }} custom-tooltip">
          <ng-container *ngIf="categorySpecial.icon.includes('b.svg')">
            <img
              src="assets/images/icons/b_white.svg"
              alt="Barbara"
              class="{{ theme }} {{ className }}__tags-list-item-img" />
          </ng-container>
          <ng-container *ngIf="!categorySpecial.icon.includes('b.svg')">
            <mat-icon
              class="material-icons-outlined {{ theme }} {{ className }}__tags-list-item-icon">
              {{ categorySpecial.icon }}
            </mat-icon>
          </ng-container>
          <span
            class="{{ theme }} {{ className }}__tags-list-item-text"
            *ngIf="
              (width <= 1024 && showText) ||
              (menuState === 'close' && width > 1024 && width < 1280) ||
              width >= 1280
            ">
            {{
              categorySpecial.name.includes('Barbara ')
                ? (categorySpecial.name | replace : 'Barbara ' : ' ')
                : categorySpecial.name
            }}
          </span>
        </div>
      </li>
    </ul>
  </div>
  <div class="{{ theme }} {{ className }}__body">
    <div class="{{ theme }} {{ className }}__body-wrapper">
      <img class="{{ theme }} {{ className }}__icon" [src]="_data.icon" alt="app icon" />
      <h3
        class="{{ theme }} {{ className }}__title"
        matTooltipPosition="above"
        matTooltip="{{ _data.name }}"
        matTooltipClass="{{ theme }} custom-tooltip">
        {{ fakeTitle !== '' ? fakeTitle : _data.name }}
      </h3>
      <mat-icon
        *ngIf="purchased"
        class="{{ theme }} {{ className }}__icon-purchased"
        matTooltipPosition="above"
        matTooltip="{{ labels.alredyInLibrary }}"
        matTooltipClass="{{ theme }} custom-tooltip"
        >book</mat-icon
      >
    </div>
    <div
      id="card-description-{{ utilsService.removeSpacesAndInsertScript(_data.name) }}"
      class="{{ theme }} {{ className }}__text"
      matTooltipPosition="above"
      matTooltip="{{ shortDescriptionTooltip }}"
      matTooltipClass="{{ theme }} custom-tooltip"
      [innerHtml]="
        fakeDescription !== ''
          ? (fakeDescription | safe : 'html')
          : (_data.shortDescription | safe : 'html')
      ">
      {{ fakeDescription !== '' ? fakeDescription : _data.shortDescription }}
    </div>
  </div>
</div>
